import { JointObject } from "../components/CanvasContext";
import {
  Abstract,
  CrossSection,
  DesignObject,
  EasyJoin,
  LineObject,
  Material,
} from "../hooks/useDrawContext";
import { getAngle, scaleVector, translate, unitVector } from "./GeomUtils";
import { abstractify } from "./ObjectUtils";
import { LRUCache } from "lru-cache";

export const getOffset = (
  cross_section: CrossSection,
  materials: Material[]
) => {
  let base_coordinates = cross_section.baseCoordinates;

  let result = [];

  let base_pos;
  if (base_coordinates.type === "relative") {
    base_pos = base_coordinates.value;
  }

  let current_pos = 0;
  for (let i = 0; i < materials.length; i += 1) {
    // set base pos
    if (
      base_coordinates.type === "center" &&
      i === base_coordinates.materialIndex
    ) {
      base_pos = current_pos + materials[i].thickness / 2;
    }

    let offset_now = [];

    offset_now.push(current_pos);
    current_pos += materials[i].thickness;
    offset_now.push(current_pos);

    result.push(offset_now);
  }

  return result.map((off) => off.map((x) => x - base_pos));
};

export const getConnectedSegments = (vertices, active_sides) => {
  let result = [];
  let closed = vertices.concat(vertices.slice(0, 2));
  let now = [];

  for (let i = 0; i < vertices.length; i += 2) {
    if (!active_sides[i / 2]) {
      if (now.length > 0) {
        result.push(now.slice());
        now = [];
      }
    } else {
      let seg_now = closed.slice(i, i + 4);
      if (now.length === 0) {
        now = now.concat(seg_now);
      } else {
        now = now.concat(seg_now.slice(2, 4));
      }
    }
  }

  if (now.length > 0) {
    result.push(now.slice());
  }

  if (active_sides[0] && active_sides[3] && result.length >= 2) {
    result = result
      .slice(1, result.length - 2)
      .concat([result[result.length - 1].concat(result[0].slice(2))]);
  }

  return result;
};

export interface BlockProps {
  grid_i: number;
  grid_j: number;
  design_objects: Abstract<DesignObject>[];
  materials: Abstract<Material>[];
  material_indices: number[];
  active_object_index: number;
  points: number[];
  passable?: boolean[];
  active_sides?: boolean[];
  editable?: boolean;
}

export interface GridProps {
  blocks: BlockProps[][];
  i_reverse: boolean;
  j_reverse: boolean;
}

const createCacheKey = (...args) => JSON.stringify(args);
const makeGridCache = new LRUCache({ max: 100 });
const calculatedGridCache = new LRUCache({ max: 100 });

export const makeGrid = (
  joint: JointObject,
  des1: LineObject,
  des2: LineObject,
  section1: CrossSection,
  section2: CrossSection,
  materials1: Material[],
  materials2: Material[]
) => {
  const cache_key = createCacheKey([
    joint,
    des1,
    des2,
    section1,
    section2,
    materials1,
    materials2,
  ]);

  if (makeGridCache.has(cache_key)) {
    return makeGridCache.get(cache_key);
  }

  if (!(section1 && section2)) {
    return;
  }

  // make grid blocks
  let joint_coord = des1.geometry.points.slice(
    2 * joint.anchors[0].pointIndex,
    2 * (joint.anchors[0].pointIndex + 1)
  );

  let u_flip = joint.anchors[0].pointIndex !== 0;
  let u = scaleVector(unitVector(des1.geometry.points), u_flip ? -1 : 1);

  let v_flip = joint.anchors[1].pointIndex !== 0;
  let v = scaleVector(unitVector(des2.geometry.points), v_flip ? -1 : 1);

  let angle = getAngle([0, 0].concat(u), [0, 0].concat(v), false);
  let flip_uv = angle >= Math.PI;

  let offsets1 = getOffset(section1, materials1);
  let offsets2 = getOffset(section2, materials2);

  let blocks = Array.from({ length: materials1.length }, () =>
    Array.from({ length: materials2.length }, () => null)
  );

  let i_reverse = u_flip !== flip_uv;
  let j_reverse = !(v_flip !== flip_uv);

  let grid: GridProps = {
    blocks: blocks,
    i_reverse: i_reverse,
    j_reverse: j_reverse,
  };

  for (let grid_i = 0; grid_i < materials1.length; grid_i++) {
    for (let grid_j = 0; grid_j < materials2.length; grid_j++) {
      let mat1_index = i_reverse ? materials1.length - 1 - grid_i : grid_i;
      let mat2_index = j_reverse ? materials2.length - 1 - grid_j : grid_j;

      let [off_a, off_b] = offsets2[mat2_index];
      let [off_c, off_d] = offsets1[mat1_index];

      off_a = (off_a / Math.abs(Math.sin(angle))) * (j_reverse ? -1 : 1);
      off_b = (off_b / Math.abs(Math.sin(angle))) * (j_reverse ? -1 : 1);
      off_c = (off_c / Math.abs(Math.sin(angle))) * (i_reverse ? -1 : 1);
      off_d = (off_d / Math.abs(Math.sin(angle))) * (i_reverse ? -1 : 1);

      let points = [
        ...translate(
          translate(
            joint_coord,
            j_reverse ? scaleVector(u, off_b) : scaleVector(u, off_a)
          ),
          i_reverse ? scaleVector(v, off_d) : scaleVector(v, off_c)
        ),
        ...translate(
          translate(
            joint_coord,
            j_reverse ? scaleVector(u, off_a) : scaleVector(u, off_b)
          ),
          i_reverse ? scaleVector(v, off_d) : scaleVector(v, off_c)
        ),
        ...translate(
          translate(
            joint_coord,
            j_reverse ? scaleVector(u, off_a) : scaleVector(u, off_b)
          ),
          i_reverse ? scaleVector(v, off_c) : scaleVector(v, off_d)
        ),
        ...translate(
          translate(
            joint_coord,
            j_reverse ? scaleVector(u, off_b) : scaleVector(u, off_a)
          ),
          i_reverse ? scaleVector(v, off_c) : scaleVector(v, off_d)
        ),
      ];

      let block: BlockProps = {
        grid_i: grid_i,
        grid_j: grid_j,
        design_objects: [abstractify(des1), abstractify(des2)],
        materials: [
          abstractify(materials1[mat1_index]),
          abstractify(materials2[mat2_index]),
        ],
        material_indices: [mat1_index, mat2_index],
        active_object_index: 0,
        points: points,
      };

      grid.blocks[grid_i][grid_j] = block;
    }
  }

  makeGridCache.set(cache_key, grid);

  return grid;
};

export const getGridBounds = (grid: GridProps) => {
  let bound1 = grid.blocks[0][grid.blocks[0].length - 1].points.slice(2, 6);
  let bound2 = grid.blocks[grid.blocks.length - 1][0].points.slice(4, 8);
  return [bound1, bound2];
};

export const calculateGrid = (
  grid: GridProps,
  section1: CrossSection,
  section2: CrossSection,
  easy_join: EasyJoin
) => {
  const cache_key = createCacheKey([grid, section1, section2, easy_join]);

  if (calculatedGridCache.has(cache_key)) {
    return calculatedGridCache.get(cache_key);
  }

  if (!(grid && section1 && section2)) {
    return;
  }

  let new_grid: GridProps = {
    blocks: Array.from({ length: grid.blocks.length }, () =>
      Array.from({ length: grid.blocks[0].length }, () => null)
    ),
    i_reverse: grid.i_reverse,
    j_reverse: grid.j_reverse,
  };

  let blocks = grid.blocks;

  let i_length = blocks.length;
  let j_length = blocks[0]?.length;

  if (!(i_length && j_length)) return;

  const get_active_index = (grid: GridProps, a, b) => {
    if (a === i_length) {
      return 1;
    } else if (b === j_length) {
      return 0;
    } else {
      let block = grid.blocks[a]?.[b];
      if (!block) {
        return null;
      }
      return block.active_object_index;
    }
  };

  const get_material_uuid = (grid: GridProps, a, b) => {
    if (a === i_length) {
      return section2.materials[grid.j_reverse ? j_length - b - 1 : b].uuid;
    } else if (b === j_length) {
      return section1.materials[grid.i_reverse ? i_length - a - 1 : a].uuid;
    } else {
      let block = grid.blocks[a]?.[b];
      if (!block) {
        return null;
      }
      return block.materials[block.active_object_index].uuid;
    }
  };

  const get_passable = (grid: GridProps, a, b) => {
    let block = grid.blocks[a]?.[b];
    if (!block) {
      return [b === j_length ? true : false, a === i_length ? true : false];
    }
    return block.passable;
  };

  // fix active object index and editable
  for (let sum = i_length + j_length - 2; sum >= 0; sum--) {
    for (
      let i = Math.max(0, sum - j_length + 1);
      i < Math.min(i_length, sum + 1);
      i++
    ) {
      let j = sum - i;

      let block = blocks[i][j];

      let active_object_index = 0;
      let editable = false;

      // passable
      let i_passable =
        get_passable(new_grid, i + 1, j)[1] &&
        get_active_index(new_grid, i + 1, j) === 1;
      let j_passable =
        get_passable(new_grid, i, j + 1)[0] &&
        get_active_index(new_grid, i, j + 1) === 0;

      let passable = [j_passable, i_passable];

      // active object index
      if (i_passable && j_passable) {
        editable = true;

        let mat1_priority = easy_join.priorities.findIndex(
          (x) => x.uuid === block.materials[0].uuid
        );
        let mat2_priority = easy_join.priorities.findIndex(
          (x) => x.uuid === block.materials[1].uuid
        );

        if (mat1_priority === mat2_priority && mat1_priority !== -1) {
          editable = false;
          passable = [false, false];
        } else if (mat1_priority === -1 && mat2_priority !== -1) {
          active_object_index = 1;
        } else if (mat1_priority < mat2_priority) {
          active_object_index = 1;
        }
      } else if (i_passable) {
        active_object_index = 1;
      } else if (j_passable) {
        active_object_index = 0;
      }

      let new_block = {
        ...block,
        active_object_index: active_object_index,
        editable: editable,
        grid_i: passable[0] ? 1 : -1,
        grid_j: passable[1] ? 1 : -1,
        passable: passable,
      };
      new_grid.blocks[i][j] = new_block;
    }
  }

  // fix active sides
  for (let i = 0; i < i_length; i++) {
    for (let j = 0; j < j_length; j++) {
      let block = new_grid.blocks[i][j];
      let block_mat = block.materials[block.active_object_index];

      let active_sides = [
        get_material_uuid(new_grid, i - 1, j) !== block_mat.uuid,
        get_material_uuid(new_grid, i, j + 1) !== block_mat.uuid,
        get_material_uuid(new_grid, i + 1, j) !== block_mat.uuid,
        get_material_uuid(new_grid, i, j - 1) !== block_mat.uuid,
      ];

      let new_block = {
        ...block,
        active_sides: active_sides,
      };

      new_grid.blocks[i][j] = new_block;
    }
  }

  calculatedGridCache.set(cache_key, new_grid);

  return new_grid;
};
