import Konva from "konva";
import {
  closestPoint,
  convertArcToSVG,
  convertToSVGPath,
  pointDistance,
} from "./GeomUtils";
import {
  Abstract,
  DesignatorLayer,
  NamedObject,
} from "../hooks/useDrawContext";
import { Path, Shape } from "react-konva";
import { useMemo } from "react";

// Get the current pointer position and adjust it based on stage position
export const getWorldPosition = (stage: Konva.Stage) => {
  const pointerPosition = stage.getPointerPosition();
  if (pointerPosition) {
    const transform = stage.getAbsoluteTransform().copy();
    transform.invert();
    const worldPosition = transform.point(pointerPosition);
    return worldPosition;
  }
  return null;
};

// Snap mouse position
export const getSnappedPoint = (
  stage: Konva.Stage,
  baseLinePoints: number[][],
  scale: number
) => {
  const snapDistance = 20 / scale;
  const pos = getWorldPosition(stage);
  const currentPos = pos ? [pos.x, pos.y] : [0, 0];

  if (baseLinePoints.length > 0) {
    const snapCandidate = closestPoint(baseLinePoints, currentPos);
    if (pointDistance(snapCandidate, currentPos) <= snapDistance) {
      return snapCandidate;
    }
  }
  return currentPos;
};

// layer to linestyle
export const layerToLinestyle = (
  layer: DesignatorLayer,
  getNamedObject: <T extends NamedObject>(obj: Abstract<T>) => T,
  global_setting: { rendering_style: string; scale_denominator: number }
) => {
  const color = getNamedObject(layer.color);
  return {
    stroke:
      global_setting.rendering_style !== "layer"
        ? color.ctb_color ?? "black"
        : getNamedObject(layer.color).value,
    fill:
      global_setting.rendering_style !== "layer"
        ? color.ctb_color ?? "black"
        : getNamedObject(layer.color).value,
    strokeWidth:
      global_setting.rendering_style === "print"
        ? color.ctb_lineweight * global_setting.scale_denominator
        : 1,
  };
};

export const OpenArc = ({
  x,
  y,
  radius,
  angle,
  angleStart,
  stroke,
  strokeWidth,
  rotation,
}: {
  x: number;
  y: number;
  radius: number;
  angle: number;
  angleStart?: number;
  stroke: string | CanvasGradient;
  strokeWidth: number;
  rotation?: number;
}) => {
  return (
    <Shape
      x={x}
      y={y}
      sceneFunc={(ctx, shape) => {
        // 열린 호를 그리는 메서드
        ctx.beginPath();
        ctx.arc(
          0,
          0,
          radius,
          ((angleStart ?? 0) * Math.PI) / 180,
          (((angleStart ?? 0) + angle) * Math.PI) / 180,
          false
        );
        ctx.strokeShape(shape); // 호를 스트로크로 그림
      }}
      stroke={stroke}
      strokeWidth={strokeWidth}
      offsetX={0}
      offsetY={0}
      rotation={rotation}
    />
  );
};

export const GeometryAsSVGPath = ({ data, stroke, strokeWidth, ...props }) => {
  const pathData = useMemo(() => convertToSVGPath(data), [data]); // Line과 Arc 데이터를 결합한 경로

  return (
    <Path
      data={pathData} // 변환된 SVG 경로
      stroke={stroke}
      strokeWidth={strokeWidth}
      {...props}
    />
  );
};
