import { FC, useState } from "react";
import List from "./List";

const PropertyRow = ({ children, small, ...props }: any) => {
  return (
    <List
      type="row"
      padding={0.5}
      // align="left"
      attach="start"
      style={{
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: small ? "1rem" : "2rem",
        width: "14rem",
        maxWidth: "14rem",
        // overflow: "hidden",
        position: "relative",
      }}
      {...props}
    >
      {children}
    </List>
  );
};

export default PropertyRow;
