import { useState } from "react";
import List from "./List";

import styles from "./Account.module.scss";
import classNames from "classnames/bind";
import Box from "./Box";

const cx = classNames.bind(styles);

const Account = ({ symbol, name, color, background_color }: any) => {
  return (
    <div className={cx("wrapper")}>
      <List type="row">
        <Box width={2} height={2}>
          <div
            className={cx("frame-icon")}
            style={{ backgroundColor: background_color, color: color }}
          >
            <Box>{symbol}</Box>
          </div>
        </Box>
        <div className={cx("text-name")}>{name}</div>
      </List>
    </div>
  );
};

export default Account;
