import {
  DrawContextStateProps,
  LineType,
  LineWeight,
  HatchType,
  ColorStyle,
  DesignatorLayer,
  Material,
  CrossSection,
  EasyJoin,
  DesignObject,
  AnyDesignObject,
} from "../hooks/useDrawContext";

import { v4 as uuid } from "uuid";
import { abstractify } from "./ObjectUtils";

const linetypes: { [name: string]: LineType } = {
  linetype1: {
    uuid: uuid(),
    type: "LineType",
    name: "linetype1",
  },
};

const lineweights: { [name: string]: LineWeight } = {
  lineweight1: {
    uuid: uuid(),
    name: "lineweight1",
    type: "LineWeight",
    value: 2,
  },
};

const hatchtypes: { [name: string]: HatchType } = {
  ins1: {
    uuid: uuid(),
    name: "Insulation 1",
    type: "HatchType",
    value: "Ins2",
  },
  diag1: {
    uuid: uuid(),
    name: "Diagonal 1",
    type: "HatchType",
    value: "Diag1",
  },
  pt1: {
    uuid: uuid(),
    name: "Scatterd Point 1",
    type: "HatchType",
    value: "Pt1",
  },
  conc1: {
    uuid: uuid(),
    name: "Concrete 1",
    type: "HatchType",
    value: "Conc1",
  },
};

const colors: { [name: string]: ColorStyle } = {
  cad1: {
    uuid: uuid(),
    type: "ColorStyle",
    value: "#ff0000",
    name: "CAD 1",
    ctb_color: "#000000",
    ctb_lineweight: 0.08,
  },
  cad2: {
    uuid: uuid(),
    type: "ColorStyle",
    value: "#ffff00",
    name: "CAD 2",
    ctb_color: "#000000",
    ctb_lineweight: 0.35,
  },
  cad3: {
    uuid: uuid(),
    type: "ColorStyle",
    value: "#00ff00",
    name: "CAD 3",
    ctb_color: "#000000",
    ctb_lineweight: 0.24,
  },
  cad4: {
    uuid: uuid(),
    type: "ColorStyle",
    value: "#00ffff",
    name: "CAD 4",
    ctb_color: "#000000",
    ctb_lineweight: 0.13,
  },
  cad5: {
    uuid: uuid(),
    type: "ColorStyle",
    value: "#0000ff",
    name: "CAD 5",
    ctb_color: "#000000",
    ctb_lineweight: 0.1,
  },
  cad6: {
    uuid: uuid(),
    type: "ColorStyle",
    value: "#ff00ff",
    name: "CAD 6",
    ctb_color: "#000000",
    ctb_lineweight: 0.1,
  },
  cad7: {
    uuid: uuid(),
    type: "ColorStyle",
    value: "#ffffff",
    name: "CAD 7",
    ctb_color: "#000000",
    ctb_lineweight: 0.18,
  },
  cad8: {
    uuid: uuid(),
    type: "ColorStyle",
    value: "#808080",
    name: "CAD 8",
    ctb_color: "#808080",
    ctb_lineweight: 0.08,
  },
  cad9: {
    uuid: uuid(),
    type: "ColorStyle",
    value: "#c0c0c0",
    name: "CAD 9",
    ctb_color: "#505050",
    ctb_lineweight: 0.1,
  },
};

export const layers: { [name: string]: DesignatorLayer } = {
  admin: {
    uuid: "admin",
    type: "DesignatorLayer",
    name: "admin",
    activated: true,
    linetype: abstractify(linetypes.linetype1),
    lineweight: abstractify(lineweights.lineweight1),
    color: abstractify(colors.cad8),
  },
  default: {
    uuid: uuid(),
    type: "DesignatorLayer",
    name: "Default",
    activated: true,
    linetype: abstractify(linetypes.linetype1),
    lineweight: abstractify(lineweights.lineweight1),
    color: abstractify(colors.cad8),
  },
  fin1: {
    uuid: uuid(),
    type: "DesignatorLayer",
    name: "Finish",
    activated: true,
    linetype: abstractify(linetypes.linetype1),
    lineweight: abstractify(lineweights.lineweight1),
    color: abstractify(colors.cad1),
  },
  conc1: {
    uuid: uuid(),
    type: "DesignatorLayer",
    name: "Concrete",
    activated: true,
    linetype: abstractify(linetypes.linetype1),
    lineweight: abstractify(lineweights.lineweight1),
    color: abstractify(colors.cad2),
  },
  ins1: {
    uuid: uuid(),
    type: "DesignatorLayer",
    name: "Insulation",
    activated: true,
    linetype: abstractify(linetypes.linetype1),
    lineweight: abstractify(lineweights.lineweight1),
    color: abstractify(colors.cad6),
  },
  mort1: {
    uuid: uuid(),
    type: "DesignatorLayer",
    name: "Mortar",
    activated: true,
    linetype: abstractify(linetypes.linetype1),
    lineweight: abstractify(lineweights.lineweight1),
    color: abstractify(colors.cad5),
  },
  hatch: {
    uuid: uuid(),
    type: "DesignatorLayer",
    name: "Hatch",
    activated: true,
    linetype: abstractify(linetypes.linetype1),
    lineweight: abstractify(lineweights.lineweight1),
    color: abstractify(colors.cad5),
  },
  outline: {
    uuid: uuid(),
    type: "DesignatorLayer",
    name: "Outline",
    activated: true,
    linetype: abstractify(linetypes.linetype1),
    lineweight: abstractify(lineweights.lineweight1),
    color: abstractify(colors.cad7),
  },
};

const materials: { [name: string]: Material } = {
  ins1: {
    uuid: uuid(),
    type: "Material",
    name: "Insulation 1",
    thickness: 150,
    hatch: abstractify(hatchtypes.ins1),
    hatchLayer: abstractify(layers.hatch),
    outlineLayer: abstractify(layers.ins1),
  },
  mort1: {
    uuid: uuid(),
    type: "Material",
    name: "Mortar 1",
    thickness: 50,
    hatch: abstractify(hatchtypes.pt1),
    hatchLayer: abstractify(layers.hatch),
    outlineLayer: abstractify(layers.mort1),
  },
  fin1: {
    uuid: uuid(),
    type: "Material",
    name: "Finish 1",
    thickness: 20,

    outlineLayer: abstractify(layers.fin1),
  },
  stone1: {
    uuid: uuid(),
    type: "Material",
    name: "Stone 1",
    thickness: 20,
    hatch: abstractify(hatchtypes.diag1),
    hatchLayer: abstractify(layers.hatch),
    outlineLayer: abstractify(layers.fin1),
  },
  conc1: {
    uuid: uuid(),
    type: "Material",
    name: "Concrete 1",
    thickness: 150,
    hatch: abstractify(hatchtypes.conc1),
    hatchLayer: abstractify(layers.hatch),
    outlineLayer: abstractify(layers.conc1),
    variable: true,
  },
};

const cross_sections: { [name: string]: CrossSection } = {
  cs1: {
    uuid: uuid(),
    name: "Floor Section 1",
    type: "InternalDetail",
    baseCoordinates: { type: "center", materialIndex: 2, value: 2 },
    internalDetailType: "CrossSection",
    materials: [
      abstractify(materials.fin1),
      abstractify(materials.mort1),
      abstractify(materials.conc1),
      abstractify(materials.ins1),
      abstractify(materials.fin1),
    ],
    thickness: 300,
  },
  cs2: {
    uuid: uuid(),
    name: "Floor Section 2",
    type: "InternalDetail",
    baseCoordinates: { type: "center", materialIndex: 2, value: 2 },
    internalDetailType: "CrossSection",
    materials: [
      abstractify(materials.fin1),
      abstractify(materials.mort1),
      abstractify(materials.conc1),
      abstractify(materials.ins1),
    ],
    thickness: 290,
  },
  cs3: {
    uuid: uuid(),
    name: "Wall Section 1",
    type: "InternalDetail",
    baseCoordinates: { type: "center", materialIndex: 2, value: 2 },
    internalDetailType: "CrossSection",
    materials: [
      abstractify(materials.fin1),
      abstractify(materials.fin1),
      abstractify(materials.conc1),
      abstractify(materials.mort1),
      abstractify(materials.stone1),
    ],
    thickness: 33,
  },
};

const easyjoins: { [name: string]: EasyJoin } = {
  ej1: {
    uuid: uuid(),
    name: "Wall join 1",
    type: "BoundaryDetail",
    boundaryDetailType: "EasyJoin",
    priorities: [
      abstractify(materials.fin1),
      abstractify(materials.mort1),
      abstractify(materials.ins1),
      abstractify(materials.stone1),
      abstractify(materials.conc1),
    ],
  },
  ej2: {
    uuid: uuid(),
    name: "Wall join 2",
    type: "BoundaryDetail",
    boundaryDetailType: "EasyJoin",
    priorities: [
      abstractify(materials.fin1),
      abstractify(materials.mort1),
      abstractify(materials.ins1),
      abstractify(materials.stone1),
      abstractify(materials.conc1),
    ],
  },
  ej3: {
    uuid: uuid(),
    name: "Wall join 3",
    type: "BoundaryDetail",
    boundaryDetailType: "EasyJoin",
    priorities: [
      abstractify(materials.fin1),
      abstractify(materials.mort1),
      abstractify(materials.ins1),
      abstractify(materials.stone1),
      abstractify(materials.conc1),
    ],
  },
  ej4: {
    uuid: uuid(),
    name: "Wall join Reversed",
    type: "BoundaryDetail",
    boundaryDetailType: "EasyJoin",
    priorities: [
      abstractify(materials.conc1),
      abstractify(materials.stone1),
      abstractify(materials.ins1),
      abstractify(materials.mort1),
      abstractify(materials.fin1),
    ],
  },
};

const design_objects: {
  [name: string]: AnyDesignObject;
} = {
  do1: {
    type: "DesignObject",
    layer: layers.default,
    uuid: uuid(),
    designObjectType: "LineObject",
    geometry: { points: [100, 100, 500, 100] },
    additionalParams: {},
    details: [],
    editable: true,
  },
};

export const getInitialDrawContext = (): DrawContextStateProps => {
  return {
    mode: "base",
    scope: null,
    layers: {
      all: Object.values(layers)
        .filter((e) => e.uuid !== "admin")
        .map((l) => abstractify(l)),
      current: abstractify(layers.default),
    },
    namedObjects: [
      linetypes,
      lineweights,
      hatchtypes,
      colors,
      layers,
      materials,
      cross_sections,
      easyjoins,
    ].reduce(
      (prev_obj, curr_obj) => ({
        ...prev_obj,
        ...Object.values(curr_obj).reduce(
          (prev, curr) => ({ ...prev, [curr.uuid]: curr }),
          {}
        ),
      }),
      {}
    ),
    designObjects: [design_objects].reduce(
      (prev_obj, curr_obj) => ({
        ...prev_obj,
        ...Object.values(curr_obj).reduce(
          (prev, curr) => ({ ...prev, [curr.uuid]: curr }),
          {}
        ),
      }),
      {}
    ),
    current_selection: [],
    global_setting: {
      uuid: uuid(),
      name: "",
      type: "GlobalSetting",
      background_color: "#ffffff",
      rendering_style: "print",
      scale_denominator: 20,
    },
  };
};
