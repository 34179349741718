import { useState } from "react";
import List from "./List";

import styles from "./NavButton.module.scss";
import classNames from "classnames/bind";
import { ReactComponent as IconNavMenu } from "../svgs/IconNavMenu.svg";
import { ReactComponent as IconNavSelect } from "../svgs/IconNavSelect.svg";
import { ReactComponent as IconNavLayers } from "../svgs/IconNavLayers.svg";
import { ReactComponent as IconNavDependencies } from "../svgs/IconNavDependencies.svg";
import { ReactComponent as IconNavAssets } from "../svgs/IconNavAssets.svg";
import { ReactComponent as IconNavStyles } from "../svgs/IconNavStyles.svg";
import { ReactComponent as IconNavSettings } from "../svgs/IconNavSettings.svg";

import Tooltip from "./Tooltip";

const cx = classNames.bind(styles);

const NavButton = ({
  type = "menu",
  focus,
  illust,
  commands,
  onClick,
}: any) => {
  const [tooltip, setTooltip] = useState(false);
  return (
    <div
      className={cx("btn-nav", focus ? "focus" : null)}
      onMouseOver={() => setTooltip(true)}
      onMouseLeave={() => setTooltip(false)}
      onClick={() => {
        onClick();
        setTooltip(false);
      }}
    >
      <List attach="center" align="center" fill>
        {type === "menu" ? (
          <IconNavMenu />
        ) : type === "select" ? (
          <IconNavSelect />
        ) : type === "layers" ? (
          <IconNavLayers />
        ) : type === "dependencies" ? (
          <IconNavDependencies />
        ) : type === "assets" ? (
          <IconNavAssets />
        ) : type === "styles" ? (
          <IconNavStyles />
        ) : type === "settings" ? (
          <IconNavSettings />
        ) : null}
      </List>
      {tooltip && <Tooltip commands={commands}>{illust ?? type}</Tooltip>}
    </div>
  );
};

export default NavButton;
