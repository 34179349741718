import { useState } from "react";
import List from "./List";

import styles from "./Division.module.scss";
import classNames from "classnames/bind";
import { ReactComponent as IconNavMenu } from "../svgs/IconNavMenu.svg";
import { ReactComponent as IconNavSelect } from "../svgs/IconNavSelect.svg";
import { ReactComponent as IconNavLayers } from "../svgs/IconNavLayers.svg";
import { ReactComponent as IconNavDependencies } from "../svgs/IconNavDependencies.svg";
import { ReactComponent as IconNavAssets } from "../svgs/IconNavAssets.svg";
import { ReactComponent as IconNavStyles } from "../svgs/IconNavStyles.svg";

const cx = classNames.bind(styles);

const Division = ({ horizontal = true, vertical, thickness = 2 }: any) => {
  return (
    <div
      className={cx("wrapper", vertical ? "vertical" : "horizontal")}
      style={
        vertical
          ? { width: thickness + "px", minWidth: thickness + "px" }
          : { height: thickness + "px", minHeight: thickness + "px" }
      }
    >
      <div className={cx("frame")}></div>
    </div>
  );
};

export default Division;
