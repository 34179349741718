import {
  createContext,
  Dispatch,
  Reducer,
  useContext,
  useReducer,
} from "react";
import Konva from "konva";
import {
  Abstract,
  AbstractDesignatorObject,
  DesignObject,
} from "../hooks/useDrawContext";
import { Stage } from "konva/lib/Stage";

export interface CanvasContextStateProps {
  editPoint: EditPoint | null;
  jointObjects: { [uuid: string]: JointObject };
  scale: number;
  stage: Konva.Stage | null;
  baseLinePoints: number[][];
}

export interface CanvasContextActionProps {
  editPoint?: EditPoint | null;
  jointObjects?: { [uuid: string]: JointObject };
  scale?: number;
  stage?: Konva.Stage;
  baseLinePoints?: number[][];
}

export interface CanvasContextProps {
  canvasContext: CanvasContextStateProps;
  setCanvasContext: Dispatch<CanvasContextActionProps>;
}

export const CanvasContext = createContext<CanvasContextProps>(null);

const reduceCanvasContext: Reducer<
  CanvasContextStateProps,
  CanvasContextActionProps
> = (state: CanvasContextStateProps, action: CanvasContextActionProps) => {
  return { ...state, ...action };
};

export interface JointObject extends AbstractDesignatorObject {
  type: "JointObject";
  anchors: {
    designObject: Abstract<DesignObject>;
    pointIndex: number; // index of the point of Design Object where joint is located
  }[];
}

export interface EditPoint {
  designObject: Abstract<DesignObject>;
  pointIndex: number;
  originalCoordinates: number[];
  currentCoordinates: number[];
}

export const CanvasContextProvider = ({ children }) => {
  const [canvasContext, setCanvasContext] = useReducer<
    Reducer<CanvasContextStateProps, CanvasContextActionProps>
  >(reduceCanvasContext, {
    editPoint: null,
    jointObjects: {},
    scale: 1,
    stage: null,
    baseLinePoints: [],
  });

  return (
    <CanvasContext.Provider
      value={{
        canvasContext,
        setCanvasContext,
      }}
    >
      {children}
    </CanvasContext.Provider>
  );
};

const useCanvasContext = () => {
  return useContext(CanvasContext);
};

export default useCanvasContext;
