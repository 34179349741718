import React, { useRef, useEffect, useState } from "react";
import List from "./List";
import styles from "./AutoScrollContainer.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const AutoScrollContainer = ({ children }: any) => {
  const containerRef = useRef(null);
  const [scrollbarHeight, setScrollbarHeight] = useState(0);
  const [scrollbarTop, setScrollbarTop] = useState(0);
  const [isDragging, setIsDragging] = useState(false); // 드래그 상태 추적

  useEffect(() => {
    const container = containerRef.current;

    const updateScrollbar = () => {
      const containerHeight = container.clientHeight;
      const contentHeight = container.scrollHeight;
      const scrollbarRatio = containerHeight / contentHeight;
      const newScrollbarHeight = containerHeight * scrollbarRatio;
      setScrollbarHeight(newScrollbarHeight);

      const scrollTop = container.scrollTop;
      const maxScrollTop = contentHeight - containerHeight;
      const newScrollbarTop =
        (scrollTop / maxScrollTop) * (containerHeight - newScrollbarHeight);
      setScrollbarTop(newScrollbarTop);
    };

    updateScrollbar(); // 초기 스크롤바 설정

    container.addEventListener("scroll", updateScrollbar);
    window.addEventListener("resize", updateScrollbar);

    return () => {
      container.removeEventListener("scroll", updateScrollbar);
      window.removeEventListener("resize", updateScrollbar);
    };
  }, []);

  const handleScrollbarMouseDown = (event) => {
    setIsDragging(true);
    const startY = event.clientY;
    const startScrollbarTop = scrollbarTop;

    const handleMouseMove = (moveEvent) => {
      // if (!isDragging) return;

      const deltaY = moveEvent.clientY - startY;
      const container = containerRef.current;
      const containerHeight = container.clientHeight;
      const newScrollbarTop = Math.max(
        0,
        Math.min(containerHeight - scrollbarHeight, startScrollbarTop + deltaY)
      );
      setScrollbarTop(newScrollbarTop);
      // console.log(newScrollbarTop);

      const contentHeight = container.scrollHeight;
      const maxScrollTop = contentHeight - containerHeight;
      const newScrollTop =
        (newScrollbarTop / (containerHeight - scrollbarHeight)) * maxScrollTop;
      container.scrollTop = newScrollTop;
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("dragend", handleMouseUp);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("dragend", handleMouseUp);
  };

  return (
    <div className={cx("wrapper")}>
      <div
        ref={containerRef}
        className={cx("auto-scroll-container")}
        style={{
          pointerEvents: isDragging ? "none" : "auto",
          userSelect: isDragging ? "none" : "auto",
        }}
      >
        {children}
      </div>
      <div
        className={cx("custom-scrollbar", isDragging && "dragging")}
        style={{
          height: `${scrollbarHeight - 12}px`,
          top: `${scrollbarTop + 6}px`,
        }}
        onMouseDown={handleScrollbarMouseDown} // 스크롤바를 드래그 시작할 때
      ></div>
    </div>
  );
};

export default AutoScrollContainer;
