import { ReactComponent as IconSymbolAdd } from "../svgs/IconSymbolAdd.svg";
import { ReactComponent as IconSymbolAlignTopLeft } from "../svgs/IconSymbolAlignTopLeft.svg";
import { ReactComponent as IconSymbolAngle } from "../svgs/IconSymbolAngle.svg";
import { ReactComponent as IconSymbolBaseOffset } from "../svgs/IconSymbolBaseOffset.svg";
import { ReactComponent as IconSymbolEasyJoin } from "../svgs/IconSymbolEasyJoin.svg";
import { ReactComponent as IconSymbolLinetype } from "../svgs/IconSymbolLinetype.svg";
import { ReactComponent as IconSymbolLineweight } from "../svgs/IconSymbolLineweight.svg";
import { ReactComponent as IconSymbolMaterial } from "../svgs/IconSymbolMaterial.svg";
import { ReactComponent as IconSymbolThickness } from "../svgs/IconSymbolThickness.svg";
import { ReactComponent as IconSymbolWallJoin } from "../svgs/IconSymbolWallJoin.svg";

const matcher = {
  "add": IconSymbolAdd,
  "aligntopleft": IconSymbolAlignTopLeft,
  "angle": IconSymbolAngle,
  "baseoffset": IconSymbolBaseOffset,
  "easyjoin": IconSymbolEasyJoin,
  "linetype": IconSymbolLinetype,
  "lineweight": IconSymbolLineweight,
  "material": IconSymbolMaterial,
  "thickness": IconSymbolThickness,
  "walljoin": IconSymbolWallJoin,
};

export type IconSymbolType = keyof typeof matcher;

interface IconSymbolProps {
  type: IconSymbolType;
}

const IconSymbol: React.FC<IconSymbolProps> = ({ type }) => {
  const IconComponent = matcher[type];
  return <IconComponent />;
};

export default IconSymbol;
