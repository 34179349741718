import { Group } from "react-konva";
import BoundaryDetailBlock, { BlockClickArea } from "./BoundaryDetailBlock";
import useDrawContext, {
  CrossSection,
  EasyJoin,
  LineObject,
  Material,
} from "../hooks/useDrawContext";
import useCanvasContext, { JointObject } from "../components/CanvasContext";
import { useEffect, useMemo, useState } from "react";
import Konva from "konva";
import {
  BlockProps,
  calculateGrid,
  GridProps,
  makeGrid,
} from "../utils/DetailUtils";

const BoundaryDetailGrid = ({ joint }: { joint: JointObject }) => {
  const {
    draw_context,
    getOverriddenDesignObject,
    getOverriddenNamedObject,
    updateDesignObject,
  } = useDrawContext();
  // const [grid, setGrid] = useState<GridProps>({
  //   blocks: [],
  //   i_reverse: false,
  //   j_reverse: false,
  // });

  let des1 = getOverriddenDesignObject(
    joint.anchors[0]?.designObject
  ) as LineObject;
  let des2 = getOverriddenDesignObject(
    joint.anchors[1]?.designObject
  ) as LineObject;
  let section1 = getOverriddenNamedObject(
    des1?.details[0]?.internalDetails[0]
  ) as CrossSection;
  let section2 = getOverriddenNamedObject(
    des2?.details[0]?.internalDetails[0]
  ) as CrossSection;
  let materials1 = section1?.materials?.map(
    getOverriddenNamedObject
  ) as Material[];
  let materials2 = section2?.materials?.map(
    getOverriddenNamedObject
  ) as Material[];
  let easy_join = getOverriddenNamedObject(
    des1?.details[0]?.boundaryDetails[0]
  ) as EasyJoin;

  let grid = useMemo(
    () =>
      makeGrid(joint, des1, des2, section1, section2, materials1, materials2),
    [joint, des1, des2, section1, section2, materials1, materials2]
  ) as GridProps;

  let calculatedGrid = useMemo(
    () => calculateGrid(grid, section1, section2, easy_join),
    [grid, section1, section2, easy_join]
  ) as GridProps;

  if (!calculatedGrid) {
    return;
  }

  // event handler
  const handleClick = (e: Konva.KonvaEventObject<Event>) => {
    let block = e.target.attrs.block as BlockProps;

    let active_material = block.materials[block.active_object_index];
    let inactive_material = block.materials[1 - block.active_object_index];

    let priority = easy_join.priorities.slice();

    let active_index = easy_join.priorities.findIndex(
      (x) => x.uuid === active_material.uuid
    );
    let inactive_index = easy_join.priorities.findIndex(
      (x) => x.uuid === inactive_material.uuid
    );

    // console.log([active_index, inactive_index]);
    // console.log([
    //   getNamedObject(active_material).name,
    //   getNamedObject(inactive_material).name,
    // ]);

    if (active_index !== -1 && inactive_index !== -1) {
      if (active_index > inactive_index) {
        priority.splice(inactive_index, 1);

        let newIndexA = priority.findIndex(
          (x) => x.uuid === active_material.uuid
        );
        priority.splice(newIndexA + 1, 0, inactive_material);
      } else {
        priority.splice(active_index, 1);
        let newIndexB = priority.findIndex(
          (x) => x.uuid === inactive_material.uuid
        );
        priority.splice(newIndexB, 0, active_material);
      }
    } else if (active_index === -1 && inactive_index !== -1) {
      priority.splice(inactive_index, 0, active_material);
    } else if (inactive_index === -1 && active_index !== -1) {
      priority.splice(active_index + 1, 0, inactive_material);
    } else {
      priority.push(active_material, inactive_material);
    }

    // let mat_names = priority.map((x) => getNamedObject(x).name);
    // console.log(mat_names);

    updateDesignObject(des1, {
      details: [
        {
          internalDetails: des1.details[0].internalDetails,
          boundaryDetails: [
            {
              ...des1.details[0].boundaryDetails[0],
              priorities: priority,
            },
          ] as EasyJoin[],
        },
      ],
    });

    // updateDesignObject(des2, {
    //   details: [
    //     {
    //       internalDetails: des2.details[0].internalDetails,
    //       boundaryDetails: [
    //         {
    //           ...des2.details[0].boundaryDetails[0],
    //           priorities: priority,
    //         },
    //       ] as EasyJoin[],
    //     },
    //   ],
    // });
  };

  return (
    <Group>
      {calculatedGrid.blocks.flat().map((block) => (
        <BoundaryDetailBlock block={block} />
      ))}
      {calculatedGrid.blocks
        .flat()
        .map((block) =>
          block.editable ? (
            <BlockClickArea block={block} onClick={handleClick} />
          ) : null
        )}
    </Group>
  );
};

export default BoundaryDetailGrid;
