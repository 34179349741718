export const baseLineColor = "#0d99ff";

// base object render
export const clickAreaWidth = 25;
export const basePointRadius = 4;

// faded object render
export const fadedOpacity = 0.25;

// selected object render
export const selectedColor = "#0d99ff";

// drawing object render
export const drawingColor = "#0d99ff";
export const drawingLineWidth = 2;
export const drawingPolygonFill = "#ffffff";
export const drawingCircleRadius = 4;
export const drawingTriangleRadius = 4.5;
export const drawingTriangleOffset = 10;

// scope object render
export const scopeColor = "#9747ff";

// detail object render

// internal detail edit canvas
export const internalDetailStageWidth = 240;
export const internalDetailStageHeight = 120;
export const internalDetailStageOffset = 16;
export const internalDetailStageGap = 4;

// scale
export const scaleBy = 1.2;
