import { ReactComponent as IconAction2DCcw } from "../svgs/IconAction2DCcw.svg";
import { ReactComponent as IconActionAbsolutePosition } from "../svgs/IconActionAbsolutePosition.svg";
import { ReactComponent as IconActionAdd } from "../svgs/IconActionAdd.svg";
import { ReactComponent as IconActionAssets } from "../svgs/IconActionAssets.svg";
import { ReactComponent as IconActionBack } from "../svgs/IconActionBack.svg";
import { ReactComponent as IconActionBreak } from "../svgs/IconActionBreak.svg";
import { ReactComponent as IconActionBreaken } from "../svgs/IconActionBreaken.svg";
import { ReactComponent as IconActionCheck } from "../svgs/IconActionCheck.svg";
import { ReactComponent as IconActionChecked } from "../svgs/IconActionChecked.svg";
import { ReactComponent as IconActionClose } from "../svgs/IconActionClose.svg";
import { ReactComponent as IconActionCopy } from "../svgs/IconActionCopy.svg";
import { ReactComponent as IconActionDelete } from "../svgs/IconActionDelete.svg";
import { ReactComponent as IconActionDownload } from "../svgs/IconActionDownload.svg";
import { ReactComponent as IconActionEdit } from "../svgs/IconActionEdit.svg";
import { ReactComponent as IconActionFilter } from "../svgs/IconActionFilter.svg";
import { ReactComponent as IconActionFoldUp } from "../svgs/IconActionFoldUp.svg";
import { ReactComponent as IconActionHidden } from "../svgs/IconActionHidden.svg";
import { ReactComponent as IconActionHide } from "../svgs/IconActionHide.svg";
import { ReactComponent as IconActionLock } from "../svgs/IconActionLock.svg";
import { ReactComponent as IconActionLocked } from "../svgs/IconActionLocked.svg";
import { ReactComponent as IconActionMatch } from "../svgs/IconActionMatch.svg";
import { ReactComponent as IconActionMore } from "../svgs/IconActionMore.svg";
import { ReactComponent as IconActionMoreVertical } from "../svgs/IconActionMoreVertical.svg";
import { ReactComponent as IconActionReset } from "../svgs/IconActionReset.svg";
import { ReactComponent as IconActionSelect } from "../svgs/IconActionSelect.svg";
import { ReactComponent as IconActionShow } from "../svgs/IconActionShow.svg";
import { ReactComponent as IconActionShown } from "../svgs/IconActionShown.svg";
import { ReactComponent as IconActionSort } from "../svgs/IconActionSort.svg";
import { ReactComponent as IconActionSync } from "../svgs/IconActionSync.svg";
import { ReactComponent as IconActionToWindow } from "../svgs/IconActionToWindow.svg";
import { ReactComponent as IconActionUnchecked } from "../svgs/IconActionUnchecked.svg";
import { ReactComponent as IconActionUnlock } from "../svgs/IconActionUnlock.svg";
import { ReactComponent as IconActionUnlocked } from "../svgs/IconActionUnlocked.svg";

const matcher = {
  "2dccw": IconAction2DCcw,
  "absoluteposition": IconActionAbsolutePosition,
  "add": IconActionAdd,
  "assets": IconActionAssets,
  "back": IconActionBack,
  "break": IconActionBreak,
  "breaken": IconActionBreaken,
  "check": IconActionCheck,
  "checked": IconActionChecked,
  "close": IconActionClose,
  "copy": IconActionCopy,
  "delete": IconActionDelete,
  "download": IconActionDownload,
  "edit": IconActionEdit,
  "filter": IconActionFilter,
  "foldup": IconActionFoldUp,
  "hidden": IconActionHidden,
  "hide": IconActionHide,
  "lock": IconActionLock,
  "locked": IconActionLocked,
  "match": IconActionMatch,
  "more": IconActionMore,
  "morevertical": IconActionMoreVertical,
  "reset": IconActionReset,
  "select": IconActionSelect,
  "show": IconActionShow,
  "shown": IconActionShown,
  "sort": IconActionSort,
  "sync": IconActionSync,
  "towindow": IconActionToWindow,
  "unchecked": IconActionUnchecked,
  "unlock": IconActionUnlock,
  "unlocked": IconActionUnlocked,
};

export type IconActionType = keyof typeof matcher;

interface IconActionProps {
  type: IconActionType;
}

const IconAction: React.FC<IconActionProps> = ({ type }) => {
  const IconComponent = matcher[type];
  return <IconComponent />;
};

export default IconAction;
