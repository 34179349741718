import { useState } from "react";
import List from "./List";

import styles from "./Box.module.scss";
import classNames from "classnames/bind";
import { ReactComponent as IconNavMenu } from "../svgs/IconNavMenu.svg";
import { ReactComponent as IconNavSelect } from "../svgs/IconNavSelect.svg";
import { ReactComponent as IconNavLayers } from "../svgs/IconNavLayers.svg";
import { ReactComponent as IconNavDependencies } from "../svgs/IconNavDependencies.svg";
import { ReactComponent as IconNavAssets } from "../svgs/IconNavAssets.svg";
import { ReactComponent as IconNavStyles } from "../svgs/IconNavStyles.svg";

const cx = classNames.bind(styles);

const Box = ({
  children,
  width,
  height,
  borderRadius,
  style,
  ...props
}: any) => {
  return (
    <div
      className={cx("wrapper")}
      {...props}
      style={{
        width: width ? width + "rem" : null,
        minWidth: width ? width + "rem" : null,
        height: height ? height + "rem" : null,
        minHeight: height ? height + "rem" : null,
        borderRadius: borderRadius ? borderRadius + "rem" : null,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default Box;
