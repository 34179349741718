import React, {
  useRef,
  useState,
  useEffect,
  ReactNode,
  CSSProperties,
  useMemo,
} from "react";
import ReactDOM from "react-dom";
import classNames from "classnames/bind";
import styles from "./DraggableList.module.scss";

const cx = classNames.bind(styles);

interface DraggableListProps {
  children: ReactNode;
}

const DraggableList: React.FC<DraggableListProps> = ({ children }) => {
  const childrenArray = useMemo(
    () => [...(React.Children.toArray(children) as React.ReactElement[])],
    [children]
  );
  const [items, setItems] = useState<React.ReactElement[]>(childrenArray);
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
  const [draggingItemStyle, setDraggingItemStyle] =
    useState<CSSProperties | null>(null);
  const [dragOffset, setDragOffset] = useState<number>(0);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
  const isDragging = useRef<boolean>(false); // 드래그 상태 추적
  const startMousePos = useRef<{ x: number; y: number } | null>(null); // 마우스 시작 위치
  const DRAG_THRESHOLD = 5; // 드래그 시작을 위한 최소 이동 거리 (픽셀 단위)

  useEffect(() => {
    // console.log(childrenArray.map((e) => e.key));
    setItems(childrenArray);
  }, [childrenArray]);

  // 드래그 중일 때 Selection 및 Pointer Events 차단
  useEffect(() => {
    if (isDragging.current && draggingIndex !== null) {
      document.body.style.userSelect = "none";
      document.body.style.pointerEvents = "none";
    } else {
      document.body.style.userSelect = "auto";
      document.body.style.pointerEvents = "auto";
    }

    return () => {
      document.body.style.userSelect = "auto";
      document.body.style.pointerEvents = "auto";
    };
  }, [draggingIndex]);

  const handleMouseDown = (index: number, event: React.MouseEvent) => {
    event.preventDefault(); // 텍스트 선택 방지
    startMousePos.current = { x: event.clientX, y: event.clientY }; // 마우스 시작 위치 저장
    setDraggingIndex(index); // 드래그할 아이템의 인덱스 저장
  };

  // 드래그 시작 로직
  const startDragging = (index: number, event: React.MouseEvent) => {
    const rect = itemRefs.current[index]?.getBoundingClientRect();
    const computedStyle = window.getComputedStyle(
      itemRefs.current[index] as Element
    ); // 원래 아이템의 스타일 가져오기
    setDragOffset(event.clientY - (rect?.top || 0));

    // 드래그하는 아이템을 복제하기 위한 스타일 설정
    if (rect) {
      setDraggingItemStyle({
        position: "fixed",
        top: rect.top,
        left: rect.left,
        width: rect.width,
        height: rect.height,
        zIndex: 9999,
        pointerEvents: "none",
      });
    }

    isDragging.current = true; // 드래그 시작 상태로 설정
  };

  const handleMouseMove = (event: MouseEvent) => {
    // 드래그 시작이 안되었을 경우 거리 계산
    if (!isDragging.current && draggingIndex !== null) {
      if (startMousePos.current) {
        const deltaX = Math.abs(event.clientX - startMousePos.current.x);
        const deltaY = Math.abs(event.clientY - startMousePos.current.y);

        // 이동 거리가 임계값 이상일 때만 드래그 시작
        if (deltaX > DRAG_THRESHOLD || deltaY > DRAG_THRESHOLD) {
          startDragging(draggingIndex, event as unknown as React.MouseEvent); // 드래그 시작
        }
      }
    }

    if (isDragging.current && draggingIndex !== null) {
      const currentY = event.clientY - dragOffset;

      // 드래그 중인 아이템의 위치 업데이트
      setDraggingItemStyle((prevStyle) =>
        prevStyle
          ? {
              ...prevStyle,
              top: currentY,
            }
          : null
      );

      const hoverIndex = getHoverIndex(currentY);

      if (
        hoverIndex !== draggingIndex &&
        hoverIndex >= 0 &&
        hoverIndex < items.length
      ) {
        const updatedItems = [...items];
        const [draggedItem] = updatedItems.splice(draggingIndex, 1);
        updatedItems.splice(hoverIndex, 0, draggedItem);

        setDraggingIndex(hoverIndex);
        setItems(updatedItems);
      }
    }
  };

  const getHoverIndex = (currentY: number): number => {
    for (let i = 0; i < itemRefs.current.length; i++) {
      const item = itemRefs.current[i];
      const rect = item?.getBoundingClientRect();

      if (rect && currentY > rect.top && currentY < rect.bottom) {
        return i;
      }
    }
    return draggingIndex!;
  };

  const handleMouseUp = () => {
    startMousePos.current = null; // 마우스 시작 위치 초기화

    if (isDragging.current) {
      setDraggingIndex(null);
      setDraggingItemStyle(null); // 드래그 복제본 숨김
      isDragging.current = false; // 드래그 상태 해제
    }
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [draggingIndex, dragOffset, items]);

  return (
    <div className={cx("draggable-list")} style={{ position: "relative" }}>
      {items.map((child, index) => (
        <div
          key={child.key}
          ref={(el) => (itemRefs.current[index] = el)}
          onMouseDown={(e) => handleMouseDown(index, e)}
          className={cx("draggable-item")}
          style={{
            visibility:
              isDragging.current && draggingIndex === index
                ? "hidden"
                : "visible", // 드래그가 시작된 후에만 아이템을 숨김
          }}
        >
          {child}
        </div>
      ))}

      {draggingItemStyle &&
        draggingIndex !== null &&
        ReactDOM.createPortal(
          <div className={cx("dragging-item")} style={draggingItemStyle}>
            {items[draggingIndex]}
          </div>,
          document.body // 포털을 사용하여 body에 렌더링
        )}
    </div>
  );
};

export default DraggableList;
