import { useEffect, useLayoutEffect, useState } from "react";

import styles from "./PropertyGroup.module.scss";
import classNames from "classnames/bind";
import List from "./List";
import PropertyRow from "./PropertyRow";
import PropertyBlock from "./PropertyBlock";
import { ReactComponent as Toggle } from "../svgs/Toggle.svg";

const cx = classNames.bind(styles);

const PropertyGroup = ({
  title,
  actions = [],
  children,
  no_divider,
  close,
}: any) => {
  const [open, setOpen] = useState(true);
  useLayoutEffect(() => {
    setOpen(!close);
  }, [close]);
  return (
    <div className={cx("wrapper")}>
      <List>
        <PropertyRow>
          <PropertyBlock type="group-title">{title}</PropertyBlock>
          {actions.map((e, idx) => (
            <PropertyBlock type="action" key={idx} {...e} />
          ))}
          <div
            className={cx("frame-toggle", open && "open")}
            onClick={() => setOpen((b) => !b)}
          >
            <List attach="center" fill>
              <Toggle />
            </List>
          </div>
        </PropertyRow>
        {open && children}
        {no_divider ? (
          <div className={cx("frame-no-divider")} />
        ) : (
          <div className={cx("frame-divider")} />
        )}
      </List>
    </div>
  );
};

export default PropertyGroup;
