import { useState, useRef, useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import List from "./List";
import styles from "./Dropdown.module.scss";
import classNames from "classnames/bind";
import AutoScrollContainer from "./AutoScrollContainer";

const cx = classNames.bind(styles);

const Dropdown = ({
  type = "bottom",
  focus,
  children = "dropdown",
  color = "light",
  padding = 0.25,
  className,
  hide,
  style,
  onClose = () => {}, // 드롭다운이 닫힐 때 호출할 함수
}: any) => {
  const tooltipRef = useRef(null);
  const parentRef = useRef(null); // 부모 요소의 ref
  const [position, setPosition] = useState({
    top: -1000,
    left: -1000,
    transform: "",
  });
  const [positionOffset, setPositionOffset] = useState({ top: 0, left: 0 });

  // 부모 요소와 툴팁의 위치를 동기화하는 함수
  const adjustPosition = () => {
    if (parentRef.current) {
      const tooltipRect = parentRef.current.getBoundingClientRect(); // 툴팁의 위치 정보
      const computedStyle = window.getComputedStyle(parentRef.current); // 툴팁의 스타일 정보
      const currentTransform =
        computedStyle.transform !== "none" ? computedStyle.transform : "";

      let newPosition = { top: 0, left: 0, transform: currentTransform };

      // 부모 요소 기준으로 툴팁의 위치를 계산 (상대적 위치 조정)
      newPosition.top = tooltipRect.top + window.scrollY;
      newPosition.left = tooltipRect.left + window.scrollX;

      // 화면 경계를 벗어나지 않도록 조정
      let newOffset = { top: 0, left: 0 };

      if (tooltipRect.right > window.innerWidth) {
        newOffset.left = window.innerWidth - tooltipRect.right - 4; // 오른쪽 경계에서 8px 떨어짐
      }

      if (tooltipRect.left < 0) {
        newOffset.left = -tooltipRect.left + 4; // 왼쪽 경계에서 8px 떨어짐
      }

      if (tooltipRect.bottom > window.innerHeight) {
        newOffset.top = window.innerHeight - tooltipRect.bottom - 4; // 아래쪽 경계에서 8px 떨어짐
      }

      if (tooltipRect.top < 0) {
        newOffset.top = -tooltipRect.top + 4; // 위쪽 경계에서 8px 떨어짐
      }

      // 새로 계산된 위치를 offset으로 설정
      setPosition(newPosition);
      setPositionOffset(newOffset);
    }

    // if (parentRef.current && parentRef.current) {
    //   const parentRect = parentRef.current.getBoundingClientRect();
    //   setPosition({
    //     top: parentRect.top + window.scrollY, // 부모 요소 상단
    //     left: parentRect.left + window.scrollX, // 부모 요소 좌측
    //   });
    // }
  };

  // 드롭다운 외부 클릭 시 닫기
  const handleClickOutside = (event) => {
    if (
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target) &&
      parentRef.current &&
      !parentRef.current.contains(event.target)
    ) {
      onClose(); // 드롭다운을 닫는 콜백 호출
    }
  };

  useEffect(() => {
    // 부모 요소의 크기 변화를 감지하는 ResizeObserver 설정
    const observer = new ResizeObserver(adjustPosition);

    // 부모 요소를 감지 대상으로 추가
    if (parentRef.current) {
      observer.observe(parentRef.current);
    }

    // 초기 위치 조정 및 이벤트 리스너 등록
    adjustPosition();
    window.addEventListener("resize", adjustPosition);
    window.addEventListener("scroll", adjustPosition);
    // document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // 컴포넌트 언마운트 시 옵저버 해제

      observer.disconnect();
      window.removeEventListener("resize", adjustPosition);
      window.removeEventListener("scroll", adjustPosition);
      // document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (tooltipRef.current) {
      const tooltipElement = tooltipRef.current;
      const computedStyle = window.getComputedStyle(tooltipElement);
      const currentTransform =
        computedStyle.transform !== "none" ? computedStyle.transform : "";

      const translateX = positionOffset.left;
      const translateY = positionOffset.top;

      // 기존 transform 값에 새로운 translate 값을 추가
      tooltipElement.style.transform = `${currentTransform} translate(${translateX}px, ${translateY}px)`;
      setPosition((pos) => ({
        ...pos,
        transform: `translate(${translateX}px, ${translateY}px)`,
      }));
    }
  }, [positionOffset]);

  const rest_style = useMemo(() => {
    if (style) {
      const { top, left, bottom, right, transform, zIndex, position, ...rest } =
        style;
      return rest;
    } else {
      return null;
    }
  }, [style]);

  // 드롭다운 내용
  const dropdownContent = (
    <div
      className={cx("wrapper", color)}
      ref={tooltipRef} // 드롭다운 내용에 대한 참조
      style={{
        position: "absolute",
        top: `${position.top}px`,
        left: `${position.left}px`,
        transform: position.transform,
        padding: `${padding}rem`,
        zIndex: 9999,
        maxHeight: `calc(100vh - ${padding * 2 + 1}rem)`,
        ...rest_style,
      }}
    >
      <AutoScrollContainer>{children}</AutoScrollContainer>
    </div>
  );

  // 모달처럼 사용하여 드롭다운 외부의 클릭을 막는 오버레이
  const overlay = (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0)", // 투명한 배경
        zIndex: 9999, // 드롭다운보다 뒤에 오도록
      }}
      onClick={handleClickOutside}
    />
  );

  return (
    <>
      {/* 부모 요소 */}
      <div
        ref={parentRef}
        className={cx("wrapper", type, color)}
        style={{
          display: "inline-block",
          position: "absolute",
          visibility: "hidden",
          padding: `${padding}rem`,
          maxHeight: `calc(100vh - ${padding * 2 + 1}rem)`,
          ...style,
        }}
      >
        <AutoScrollContainer>{children}</AutoScrollContainer>
      </div>

      {/* 드롭다운 외부 클릭을 막는 오버레이와 드롭다운 */}
      {!hide &&
        createPortal(
          <>
            {overlay} {/* 모달처럼 동작하게 하는 오버레이 */}
            {dropdownContent} {/* 실제 드롭다운 내용 */}
          </>,
          document.body
        )}
    </>
  );
};

export default Dropdown;
