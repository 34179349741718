import { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import List from "./List";
import styles from "./Tooltip.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const Tooltip = ({
  type = "right",
  focus,
  children = "tooltip",
  commands = [],
  className,
}: any) => {
  const tooltipRef = useRef(null);
  const parentRef = useRef(null); // 부모 요소의 ref
  const [positionOffset, setPositionOffset] = useState({ top: 0, left: 0 });
  const [position, setPosition] = useState({
    top: -1000,
    left: -1000,
    transform: "",
  });

  // 툴팁의 위치 조정 및 화면 경계 벗어남 방지 함수
  const adjustPosition = () => {
    if (tooltipRef.current) {
      const tooltipRect = tooltipRef.current.getBoundingClientRect(); // 툴팁의 위치 정보
      const computedStyle = window.getComputedStyle(tooltipRef.current); // 툴팁의 스타일 정보
      const currentTransform =
        computedStyle.transform !== "none" ? computedStyle.transform : "";

      let newPosition = { top: 0, left: 0, transform: currentTransform };

      // 부모 요소 기준으로 툴팁의 위치를 계산 (상대적 위치 조정)
      newPosition.top = tooltipRect.top + window.scrollY;
      newPosition.left = tooltipRect.left + window.scrollX;

      // 화면 경계를 벗어나지 않도록 조정
      let newOffset = { top: 0, left: 0 };

      if (tooltipRect.right > window.innerWidth) {
        newOffset.left = window.innerWidth - tooltipRect.right - 8; // 오른쪽 경계에서 8px 떨어짐
      }

      if (tooltipRect.left < 0) {
        newOffset.left = -tooltipRect.left + 8; // 왼쪽 경계에서 8px 떨어짐
      }

      if (tooltipRect.bottom > window.innerHeight) {
        newOffset.top = window.innerHeight - tooltipRect.bottom - 8; // 아래쪽 경계에서 8px 떨어짐
      }

      if (tooltipRect.top < 0) {
        newOffset.top = -tooltipRect.top + 8; // 위쪽 경계에서 8px 떨어짐
      }

      // 새로 계산된 위치를 offset으로 설정
      setPosition(newPosition);
      setPositionOffset(newOffset);
    }
  };

  useEffect(() => {
    adjustPosition();
    window.addEventListener("resize", adjustPosition);
    window.addEventListener("scroll", adjustPosition);

    return () => {
      window.removeEventListener("resize", adjustPosition);
      window.removeEventListener("scroll", adjustPosition);
    };
  }, []);

  useEffect(() => {
    if (tooltipRef.current) {
      const tooltipElement = tooltipRef.current;
      const computedStyle = window.getComputedStyle(tooltipElement);
      const currentTransform =
        computedStyle.transform !== "none" ? computedStyle.transform : "";

      const translateX = positionOffset.left;
      const translateY = positionOffset.top;

      // 기존 transform 값에 새로운 translate 값을 추가
      tooltipElement.style.transform = `${currentTransform} translate(${translateX}px, ${translateY}px)`;
      setPosition((pos) => ({
        ...pos,
        transform: `translate(${translateX}px, ${translateY}px)`,
      }));
    }
  }, [positionOffset]);

  const tooltipContent = (
    <div
      className={cx("wrapper")}
      style={{
        position: "absolute",
        top: `${position.top}px`,
        left: `${position.left}px`,
        transform: position.transform,
        zIndex: 9999, // 다른 요소들 위에 표시
        visibility: "visible", // 툴팁이 보이도록 처리
      }}
    >
      <List type="row" gap={0.5}>
        {children}
        {commands.toReversed().map((command, idx) => (
          <div
            key={idx}
            className={cx(
              "commands",
              idx === commands.length - 1 ? "first" : null
            )}
          >
            {command}
          </div>
        ))}
      </List>
    </div>
  );

  return (
    <>
      {/* 원래 위치의 툴팁 (invisible) */}
      <div
        ref={tooltipRef}
        className={cx("wrapper", type)}
        style={{
          visibility: "hidden", // 숨긴 상태로 DOM에 남아 위치 계산
          position: "absolute",
        }}
      >
        <List type="row" gap={0.5}>
          {children}
          {commands.toReversed().map((command, idx) => (
            <div
              key={idx}
              className={cx(
                "commands",
                idx === commands.length - 1 ? "first" : null
              )}
            >
              {command}
            </div>
          ))}
        </List>
      </div>

      {/* 포탈을 사용해 상단에서 실제로 보이는 툴팁 */}
      {createPortal(tooltipContent, document.body)}
    </>
  );
};

export default Tooltip;
