import React, { forwardRef } from "react";
// { useEffect }

import styles from "./List.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const List = forwardRef(
  (
    {
      children,
      type,
      align,
      attach,
      multiple_line,
      gap,
      height,
      fill,
      onMouseOver,
      onMouseEnter,
      onMouseLeave,
      onClick,
      relative,
      disable,
      padding,
      fillX,
      fillY = false,
      style = {},
      ...props
    }: any,
    ref: any
  ) => {
    return (
      <div
        className={cx(
          "wrapper",
          relative ? "relative" : "",
          "type-" + type,
          "align-" + align,
          "attach-" + attach,
          multiple_line ? "multiple-line" : "",
          fill ? "full" : "tight",
          disable ? "disable" : "",
          fillX ? "fillX" : ""
        )}
        ref={ref}
        style={
          !fill
            ? {
                height: height + "rem",
                gap: gap + "rem",
                padding: padding + "rem",
                ...style,
              }
            : type === "row" && !fillY
            ? {
                height: height + "rem",
                gap: gap + "rem",
                padding: padding + "rem",
                width: "calc(100% - " + padding * 2 + "rem)",
                ...style,
              }
            : {
                height: "calc(100% - " + padding * 2 + "rem)",
                gap: gap + "rem",
                width: "calc(100% - " + padding * 2 + "rem)",
                padding: padding + "rem",
                ...style,
              }
        }
        onMouseOver={onMouseOver}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        {...props}
      >
        {children}
      </div>
    );
  }
);

List.defaultProps = {
  children: null,
  type: "column",
  align: "center",
  attach: "default",
  multiple_line: false,
  gap: 0,
  height: "auto",
  padding: 0,
};

export default List;

// ### List

// - type : column / row / grid
// - align : left / center / right
// - attach : start / default / end / space
// - multiple_line : boolean
// - gap : int
