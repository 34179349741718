import { Fragment, useState } from "react";
import List from "./List";

import styles from "./CommandSuggests.module.scss";
import classNames from "classnames/bind";
import { ReactComponent as IconActionBack } from "../svgs/IconActionBack.svg";
import { ReactComponent as IconNavSelect } from "../svgs/IconNavSelect.svg";
import { ReactComponent as IconNavLayers } from "../svgs/IconNavLayers.svg";
import { ReactComponent as IconNavDependencies } from "../svgs/IconNavDependencies.svg";
import { ReactComponent as IconNavAssets } from "../svgs/IconNavAssets.svg";
import { ReactComponent as IconNavStyles } from "../svgs/IconNavStyles.svg";
import Division from "./Division";
import PropertyRow from "./PropertyRow";
import PropertyBlock from "./PropertyBlock";
import LayerRow from "./LayerRow";
import IconCommand from "./IconCommand";
import Tooltip from "./Tooltip";

const cx = classNames.bind(styles);

const sample_commands_old = [
  { name: "polyline", illust: "Polyline", commands: ["PL"] },
  { name: "point", illust: "Point", commands: ["P"] },
  { name: "group", illust: "Group", commands: ["G", "Ctrl+G"] },
  { name: "explode", illust: "Explode", commands: ["X"] },
  { name: "ungroup", illust: "UnGroup", commands: ["UG", "Ctrl+Shift+G"] },
];

const sample_commands = [
  { name: "line", illust: "Line", commands: ["L"] },
  { name: "polyline", illust: "Polyline", commands: ["PL"] },
  { name: "block", illust: "Block", commands: ["BL"] },
  { name: "select", illust: "Select", commands: ["SEL", "Esc"] },
  { name: "scale", illust: "Scale", commands: ["SC"] },
];

const CommandSuggests = ({ suggest_commands = sample_commands }: any) => {
  return (
    <div className={cx("wrapper")}>
      <List fill>
        {suggest_commands.map((e, idx) => (
          <div
            key={idx}
            className={cx("frame-command-line", idx === 0 ? "active" : null)}
          >
            <List type="row" align="center" fill fillY>
              <div className={cx("frame-command-symbol")}>
                <List attach="center" fill>
                  <IconCommand type={e.name} />
                </List>
              </div>
              <div className={cx("frame-command-text")}>
                <List type="row" align="center" attach="space" fill fillY>
                  <div>{e.illust}</div>
                  <List
                    type="row"
                    align="center"
                    gap={0.5}
                    style={{ height: "100%" }}
                  >
                    {e.commands.toReversed().map((command, idx) => (
                      <div
                        className={cx(
                          "commands",
                          idx === e.commands.length - 1 ? "first" : null
                        )}
                      >
                        {command}
                      </div>
                    ))}
                  </List>
                </List>
              </div>
            </List>
          </div>
        ))}
      </List>
    </div>
  );
};

export default CommandSuggests;
