import { Fragment, useState } from "react";
import List from "./List";

import styles from "./DropdownObject.module.scss";
import classNames from "classnames/bind";
import Division from "./Division";
import PropertyRow from "./PropertyRow";
import PropertyBlock from "./PropertyBlock";
import LayerRow from "./LayerRow";
import IconCommand from "./IconCommand";
import Tooltip from "./Tooltip";
import useDrawContext, { ColorStyle } from "../hooks/useDrawContext";
import StandardSlabImg from "../imgs/standard_slab.png";
import { ReactComponent as Search } from "../svgs/Search.svg";
import Box from "./Box";

const cx = classNames.bind(styles);

const DropdownObject = ({
  object_type = "InternalDetail",
  onClose = () => {},
  onClick = () => {},
}: any) => {
  const { draw_context } = useDrawContext();
  const [hover_idx, setHoverIdx] = useState<boolean | number>(false);

  return (
    <div className={cx("wrapper")}>
      <List fill>
        <List type="row" fill>
          <Box width={2} height={2}>
            <Search width={28} />
          </Box>
          <input
            className={cx("frame-search-input")}
            placeholder="Search"
            autoFocus
          />
        </List>
        <div className={cx("frame-divider")} />
        {Object.values(draw_context.namedObjects)
          .filter((e) => e.type === object_type)
          .map((e, idx) => (
            <List
              type="row"
              key={idx}
              onMouseEnter={() => setHoverIdx(idx)}
              onMouseLeave={() => setHoverIdx(false)}
              fill
              // style={{ width: "14rem" }}
            >
              <PropertyBlock
                type="object"
                value={e.name}
                onClick={() => {
                  onClick(e);
                  onClose();
                  setHoverIdx(false);
                }}
              >
                {object_type === "ColorStyle" ? (
                  <div
                    className={cx("frame-color")}
                    style={{ backgroundColor: (e as ColorStyle).value }}
                  ></div>
                ) : (
                  <img src={StandardSlabImg} />
                )}
              </PropertyBlock>
              {hover_idx === idx && (
                <PropertyBlock
                  type="action"
                  icon_type="edit"
                  illust="Edit asset"
                ></PropertyBlock>
              )}
            </List>
            // <div className={cx("frame-object")}>
            //   {e.name}
            //   {e.thickness}
            // </div>
          ))}
      </List>
    </div>
  );
};

export default DropdownObject;
