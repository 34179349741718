import { Circle, Group, Line, RegularPolygon } from "react-konva";
import useDrawContext, {
  CrossSection,
  DesignatorLayer,
  DesignObject,
  LineObject,
} from "../hooks/useDrawContext";
import {
  drawingPolygonFill,
  drawingCircleRadius,
  drawingColor,
  drawingLineWidth,
  drawingTriangleRadius,
  drawingTriangleOffset,
  selectedColor,
  fadedOpacity,
  clickAreaWidth,
  basePointRadius,
  scopeColor,
} from "../utils/CanvasConstants";
import {
  getAngle,
  offsetSegment,
  rotateVector,
  translate,
  unitVector,
} from "../utils/GeomUtils";
import { abstractify, isLineObject } from "../utils/ObjectUtils";
import { SelectedDesignObject } from "../components/Canvas";
import {
  getSnappedPoint,
  getWorldPosition,
  layerToLinestyle,
} from "../utils/KonvaUtils";
import Konva from "konva";
import DetailObject from "./DetailObject";
import useCanvasContext from "../components/CanvasContext";
import { getOffset } from "../utils/DetailUtils";
import { useEffect, useMemo, useState } from "react";

export const EditingObject = ({
  current_points,
  is_scope = false,
  editable,
  design_object,
  scale,
}: {
  current_points: number[];
  is_scope: boolean;
  editable: boolean;
  design_object?: DesignObject;
  scale?: number;
}) => {
  const { canvasContext, setCanvasContext } = useCanvasContext();
  const { draw_context, setDrawContext, getDesignObject, updateDesignObject } =
    useDrawContext();
  const [editing, setEditing] = useState<number>(-1);
  const [hover, setHover] = useState<number>(-1);

  const handleMouseDown = (idx) => {
    setEditing(idx);
  };
  const handleMouseUp = () => {
    setEditing(-1);
  };
  useEffect(() => {
    const handleMousemove = (e) => {
      if (editing >= 0) {
        const line = design_object as LineObject;
        if (line) {
          const pos = getSnappedPoint(
            canvasContext.stage,
            canvasContext.baseLinePoints,
            canvasContext.scale
          );
          let point_index = editing as number;

          let new_line = line.geometry.points
            .slice(0, 2 * point_index)
            .concat(pos)
            .concat(line.geometry.points.slice(2 * (point_index + 1)));
          updateDesignObject<LineObject>(line, {
            geometry: { ...line.geometry, points: new_line },
          });
        }
      }
    };
    window.addEventListener("mousemove", handleMousemove);
    return () => window.removeEventListener("mousemove", handleMousemove);
  }, [editing]);

  // current points empty
  if (current_points.length < 2) {
    return;
  }

  // styles
  const line_style = {
    stroke: is_scope ? scopeColor : drawingColor,
    strokeWidth: drawingLineWidth,
  };

  const circle_style = {
    stroke: is_scope ? scopeColor : drawingColor,
    strokeWidth: drawingLineWidth,
    fill: drawingPolygonFill,
    radius: drawingCircleRadius,
  };

  const triangle_style = {
    stroke: is_scope ? scopeColor : drawingColor,
    strokeWidth: drawingLineWidth,
    fill: drawingPolygonFill,
    radius: drawingTriangleRadius,
  };

  const line = (
    <Line points={current_points} {...line_style} strokeScaleEnabled={false} />
  );

  const circle = (
    <Circle
      x={current_points[0]}
      y={current_points[1]}
      {...circle_style}
      scaleX={1 / (scale ?? canvasContext.scale)}
      scaleY={1 / (scale ?? canvasContext.scale)}
      fill={hover === 0 || editing == 0 ? line_style.stroke : "white"}
      strokeScaleEnabled={false}
      onMouseEnter={() => setHover(0)}
      onMouseLeave={() => setHover(-1)}
      onMouseDown={() => handleMouseDown(0)}
      onMouseUp={handleMouseUp}
      listening={editable}
      hitStrokeWidth={20}
    />
  );

  if (current_points.length < 4) {
    return <Group>{circle}</Group>;
  }

  const vector = unitVector(current_points);

  // check for NaN
  if (vector.some((x) => isNaN(x))) {
    return;
  }

  const normal = rotateVector(vector, false);
  const rot = getAngle([0, 0, 1, 0], current_points);
  const tri2point = [
    current_points[0] +
      (normal[0] * drawingTriangleOffset) / (scale ?? canvasContext.scale),
    current_points[1] +
      (normal[1] * drawingTriangleOffset) / (scale ?? canvasContext.scale),
  ];

  const triangle1 = (
    <RegularPolygon
      sides={3}
      x={current_points[2]}
      y={current_points[3]}
      rotation={rot + 90}
      {...triangle_style}
      scaleX={1 / (scale ?? canvasContext.scale)}
      scaleY={1 / (scale ?? canvasContext.scale)}
      strokeScaleEnabled={false}
      scaleDisabled={true}
      design_object={design_object}
      line_index={1}
      fill={hover === 1 || editing === 1 ? line_style.stroke : "white"}
      onMouseEnter={() => setHover(1)}
      onMouseLeave={() => setHover(-1)}
      onMouseDown={() => handleMouseDown(1)}
      onMouseUp={handleMouseUp}
      listening={editable}
      hitStrokeWidth={20}
    />
  );

  const triangle2 = (
    <RegularPolygon
      sides={3}
      x={tri2point[0]}
      y={tri2point[1]}
      rotation={rot}
      {...triangle_style}
      scaleX={1 / (scale ?? canvasContext.scale)}
      scaleY={1 / (scale ?? canvasContext.scale)}
      strokeScaleEnabled={false}
      scaleDisabled={true}
    />
  );

  return (
    <Group>
      {line}
      {circle}
      {triangle1}
      {triangle2}
    </Group>
  );
};

export const BaseLine = ({
  design_object,
  style_override,
}: {
  design_object: LineObject;
  style_override: Object;
}) => {
  const { draw_context, getNamedObject } = useDrawContext();
  const layer = useMemo(
    () => getNamedObject(design_object.layer),
    [design_object.layer, getNamedObject]
  );
  const linestyle = useMemo(
    () => layerToLinestyle(layer, getNamedObject, draw_context.global_setting),
    [layer, getNamedObject, draw_context]
  );

  return (
    <Line
      design_object={design_object}
      points={design_object.geometry.points}
      {...linestyle}
      {...style_override}
      strokeScaleEnabled={
        draw_context.global_setting.rendering_style === "print"
      }
    />
  );
};

export const ClickArea = ({
  design_object,
  is_locked,
}: {
  design_object: LineObject;
  is_locked: boolean;
}) => {
  const {
    draw_context,
    setDrawContext,
    getDesignObject,
    getOverriddenNamedObject,
  } = useDrawContext();
  const { canvasContext } = useCanvasContext();

  let seg = design_object.geometry.points;

  let cross_section = getOverriddenNamedObject(
    design_object.details?.[0]?.internalDetails?.[0]
  ) as CrossSection;

  let materials = cross_section?.materials?.map(getOverriddenNamedObject);

  let offsets = cross_section ? getOffset(cross_section, materials) : null;
  let dist1 = cross_section
    ? offsets[0][0]
    : clickAreaWidth / 2 / canvasContext.scale;
  let dist2 = cross_section
    ? offsets[offsets.length - 1][1]
    : -clickAreaWidth / 2 / canvasContext.scale;
  let offseg1 = offsetSegment(seg, dist1);
  let offseg2 = offsetSegment(seg, dist2);

  offseg2 = offseg2.slice(2).concat(offseg2.slice(0, 2));
  let area = offseg1.concat(offseg2);

  // event listeners

  const handleLineClick = (e: Konva.KonvaEventObject<Event>) => {
    let design_object = getDesignObject(e.target.attrs.design_object);

    if (draw_context.scope === null && design_object) {
      setDrawContext({
        mode: "edit",
        current_selection: [abstractify(design_object)],
      });
    }
  };

  const handleLineDblClick = (e: Konva.KonvaEventObject<Event>) => {
    let design_object = getDesignObject(e.target.attrs.design_object);

    if (draw_context.scope === null && design_object) {
      setDrawContext({
        mode: "base",
        scope: abstractify(design_object),
        current_selection: [],
      });
    }
  };

  return (
    <Line
      design_object={abstractify(design_object)}
      points={area}
      closed={true}
      stroke={null}
      fill={null}
      strokeScaleEnabled={false}
      onClick={is_locked ? null : handleLineClick}
      onDblClick={is_locked ? null : handleLineDblClick}
    />
  );
};

export const CanvasDesignObject = ({
  design_object,
}: {
  design_object: DesignObject;
}) => {
  const { draw_context, getNamedObject } = useDrawContext();

  // only LineObject for now
  if (!isLineObject(design_object)) {
    return;
  }

  let layer = getNamedObject(design_object.layer);
  if (!layer.activated) {
    return;
  }

  // is locked
  const is_locked = layer.locked;

  // is selected
  const is_selected =
    SelectedDesignObject(draw_context)?.uuid === design_object.uuid;

  // is editing
  const is_editing = is_selected && draw_context.mode === "edit";

  // is scope
  const is_scope = draw_context.scope?.uuid === design_object.uuid;

  // is faded
  const is_faded = !is_scope && draw_context.scope !== null;

  // has detail
  const has_detail = design_object.details.length > 0;

  // style override
  let style_override = {};
  if (is_faded) {
    style_override["opacity"] = fadedOpacity;
  }
  if (is_selected) {
    style_override["stroke"] = selectedColor;
    style_override["opacity"] = has_detail ? 1 : 0.25;
    style_override["fill"] = selectedColor;
  }

  return (
    <Group
      design_object={design_object}
      layer={abstractify(design_object.layer)}
    >
      {has_detail ? (
        <DetailObject
          design_object={design_object}
          detail={design_object.details[0]}
          selected={is_selected}
          is_locked={is_locked}
        />
      ) : (
        <BaseLine
          design_object={design_object}
          style_override={style_override}
        />
      )}
      <ClickArea design_object={design_object} is_locked={is_locked} />
      {is_editing && (
        <>
          <EditingObject
            current_points={design_object.geometry.points}
            is_scope={false}
            editable
            design_object={design_object}
          />
          {/* <BasePoint
            design_object={design_object}
            style_override={style_override}
            is_locked={is_locked}
          /> */}
        </>
      )}
      {is_scope && (
        <EditingObject
          current_points={design_object.geometry.points}
          is_scope={true}
          editable
          design_object={design_object}
        />
      )}
    </Group>
  );
};
