import { Group, Line } from "react-konva";
import Hatch from "../design_objects/Hatch";
import useDrawContext, { DesignatorLayer } from "../hooks/useDrawContext";
import { useMemo } from "react";
import { layers } from "./getInitialDrawContext";
import useCanvasContext from "../components/CanvasContext";
import Konva from "konva";

const HatchSample = ({ x, y }) => {
  const { draw_context, getNamedObject } = useDrawContext();
  const { canvasContext } = useCanvasContext();
  const layer1 = getNamedObject<DesignatorLayer>(layers.hatch);
  const conc = getNamedObject<DesignatorLayer>(layers.conc1);
  const ins = getNamedObject<DesignatorLayer>(layers.ins1);
  const fin = getNamedObject<DesignatorLayer>(layers.fin1);
  const mort = getNamedObject<DesignatorLayer>(layers.mort1);
  const out = getNamedObject<DesignatorLayer>(layers.outline);
  const getStyle = useMemo(
    () => (layer: DesignatorLayer) => {
      const color = getNamedObject(
        getNamedObject<DesignatorLayer>(layer)?.color
      );
      return {
        stroke:
          (draw_context.global_setting.rendering_style === "layer"
            ? color?.value
            : color?.ctb_color) ?? "black",
        strokeWidth:
          draw_context.global_setting.rendering_style !== "print"
            ? 1
            : color?.ctb_lineweight
            ? color?.ctb_lineweight *
              draw_context.global_setting.scale_denominator
            : 1,
      };
    },
    [draw_context.global_setting, draw_context.namedObjects]
  );

  const sample_data: (
    | {
        type: "Line";
        x: number;
        y: number;
        points: number[];
        stroke: string;
        strokeWidth: number;
      }
    | {
        type: string;
        x: number;
        y: number;
        boundary: number[];
        pivot_point: number[];
        unit_direction: number[];
        layer: DesignatorLayer;
      }
  )[] = useMemo(
    () => [
      {
        type: "Line",
        x: 0,
        y: 0,
        points: [
          -80, -500, 240, -500, 240, 20, 1700, 20, 1700, 830, 240, 830, 240,
          1000, -80, 1000, -80, -500,
        ],
        stroke: getStyle(out).stroke,
        strokeWidth:
          getStyle(out).strokeWidth /
          (draw_context.global_setting.rendering_style === "print"
            ? 1
            : canvasContext.scale),
      },
      {
        type: "Line",
        x: 200,
        y: -500,
        points: [20, 0, 20, 520, 0, 520, 0, 0, 20, 0],
        stroke: getStyle(fin).stroke,
        strokeWidth:
          getStyle(fin).strokeWidth /
          (draw_context.global_setting.rendering_style === "print"
            ? 1
            : canvasContext.scale),
      },
      {
        type: "Line",
        x: 200,
        y: 830,
        points: [20, 0, 20, 170, 0, 170, 0, 0, 20, 0],
        stroke: getStyle(fin).stroke,
        strokeWidth:
          getStyle(fin).strokeWidth /
          (draw_context.global_setting.rendering_style === "print"
            ? 1
            : canvasContext.scale),
      },
      {
        type: "Line",
        x: 220,
        y: 830,
        points: [20, 0, 20, 170, 0, 170, 0, 0, 20, 0],
        stroke: getStyle(fin).stroke,
        strokeWidth:
          getStyle(fin).strokeWidth /
          (draw_context.global_setting.rendering_style === "print"
            ? 1
            : canvasContext.scale),
      },
      {
        type: "Line",
        x: 220,
        y: -500,
        points: [20, 0, 20, 520, 0, 520, 0, 0, 20, 0],
        stroke: getStyle(fin).stroke,
        strokeWidth:
          getStyle(fin).strokeWidth /
          (draw_context.global_setting.rendering_style === "print"
            ? 1
            : canvasContext.scale),
      },
      {
        type: "Line",
        x: -80,
        y: -500,
        points: [50, 0, 50, 1500, 0, 1500, 0, 0, 50, 0],
        stroke: getStyle(fin).stroke,
        strokeWidth:
          getStyle(fin).strokeWidth /
          (draw_context.global_setting.rendering_style === "print"
            ? 1
            : canvasContext.scale),
      },
      {
        type: "Diag1",
        x: -80,
        y: -500,
        boundary: [50, 0, 50, 1500, 0, 1500, 0, 0, 50, 0],
        pivot_point: [-50, 600],
        unit_direction: [-50, 600],
        layer: layer1,
      },
      {
        type: "Line",
        x: 0,
        y: -500,
        points: [200, 0, 200, 1500, 0, 1500, 0, 0, 200, 0],
        stroke: getStyle(conc).stroke,
        strokeWidth:
          getStyle(conc).strokeWidth /
          (draw_context.global_setting.rendering_style === "print"
            ? 1
            : canvasContext.scale),
      },
      {
        type: "Conc1",
        x: 0,
        y: -500,
        boundary: [200, 0, 200, 1500, 0, 1500, 0, 0, 200, 0],
        pivot_point: [0, 600],
        unit_direction: [1, 0],
        layer: layer1,
      },
      {
        type: "Line",
        x: 200,
        y: 20,
        points: [1500, 0, 1500, 30, 0, 30, 0, 0, 1500, 0],
        stroke: getStyle(fin).stroke,
        strokeWidth:
          getStyle(fin).strokeWidth /
          (draw_context.global_setting.rendering_style === "print"
            ? 1
            : canvasContext.scale),
      },
      {
        type: "Line",
        x: 200,
        y: 800,
        points: [1500, 0, 1500, 30, 0, 30, 0, 0, 1500, 0],
        stroke: getStyle(fin).stroke,
        strokeWidth:
          getStyle(fin).strokeWidth /
          (draw_context.global_setting.rendering_style === "print"
            ? 1
            : canvasContext.scale),
      },
      {
        type: "Line",
        x: 200,
        y: 250,
        points: [1500, 0, 1500, 150, 0, 150, 0, 0, 1500, 0],
        stroke: getStyle(ins).stroke,
        strokeWidth:
          getStyle(ins).strokeWidth /
          (draw_context.global_setting.rendering_style === "print"
            ? 1
            : canvasContext.scale),
      },
      {
        type: "Ins2",
        x: 200,
        y: 250,
        boundary: [1500, 0, 1500, 150, 0, 150, 0, 0],
        pivot_point: [5, 0],
        unit_direction: [1, 0],
        layer: layer1,

        scale: 1.5,
      },
      {
        type: "Line",
        x: 200,
        y: 50,
        points: [1500, 0, 1500, 50, 0, 50, 0, 0, 1500, 0],
        stroke: getStyle(mort).stroke,
        strokeWidth:
          getStyle(mort).strokeWidth /
          (draw_context.global_setting.rendering_style === "print"
            ? 1
            : canvasContext.scale),
      },
      {
        type: "Pt1",
        x: 200,
        y: 50,
        boundary: [1500, 0, 1500, 50, 0, 50, 0, 0],
        pivot_point: [100, 0],
        unit_direction: [1, 0.5],
        layer: layer1,
      },
      {
        type: "Line",
        x: 200,
        y: 100,
        points: [1500, 0, 1500, 150, 0, 150, 0, 0, 1500, 0],
        stroke: getStyle(conc).stroke,
        strokeWidth:
          getStyle(conc).strokeWidth /
          (draw_context.global_setting.rendering_style === "print"
            ? 1
            : canvasContext.scale),
      },
      {
        type: "Conc1",
        x: 200,
        y: 100,
        boundary: [1500, 0, 1500, 150, 0, 150, 0, 0],
        pivot_point: [-200, 0],
        unit_direction: [1, 0],
        layer: layer1,
      },
    ],
    []
  );
  return (
    <Group x={0} y={0}>
      {sample_data.map((data, idx) =>
        data.type === "Line" ? (
          <Line {...data}></Line>
        ) : (
          <Hatch
            {...(data as {
              type: string;
              boundary: number[];
              pivot_point: number[];
              unit_direction: number[];
              layer: DesignatorLayer;
            })}
          ></Hatch>
        )
      )}
    </Group>
  );
};

export default HatchSample;
