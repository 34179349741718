import { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import List from "./List";
import styles from "./DropdownCommand.module.scss";
import classNames from "classnames/bind";
import Box from "./Box";

const cx = classNames.bind(styles);

const DropdownCommand = ({
  icon,
  commands = [],
  children,
  onClick = () => {},
}: any) => {
  return (
    <div className={cx("wrapper")} onClick={onClick}>
      <List fill align="left" attach="center">
        {children}
      </List>
    </div>
  );
};

export default DropdownCommand;
