import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CYPage from "./pages/CYPage";
import DraggableList from "./components/DraggableList";
import PropertyRow from "./components/PropertyRow";
import PropertyBlock from "./components/PropertyBlock";
import IconSymbol from "./components/IconSymbol";
// import DrawPage from "./old/DrawPage";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/dh" element={<DrawPage />} /> */}
        <Route path="/cy" element={<CYPage />} />
        <Route
          path="/test"
          element={
            <DraggableList>
              <PropertyRow>
                <PropertyBlock
                  type="object-selection"
                  value="Finish 1"
                ></PropertyBlock>
                <PropertyBlock
                  type="action"
                  icon_type="delete"
                  illust="Delete"
                />
              </PropertyRow>
              <PropertyRow>
                <PropertyBlock
                  type="object-selection"
                  value="Finish 1"
                ></PropertyBlock>
                <PropertyBlock type="object-input" value="150">
                  <IconSymbol type="thickness" />
                </PropertyBlock>
                <PropertyBlock
                  type="action"
                  icon_type="delete"
                  illust="Delete"
                />
              </PropertyRow>
              <div>d</div>
            </DraggableList>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
