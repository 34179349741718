import { ReactComponent as IconCommandBlock } from "../svgs/IconCommandBlock.svg";
import { ReactComponent as IconCommandCommand } from "../svgs/IconCommandCommand.svg";
import { ReactComponent as IconCommandCopy } from "../svgs/IconCommandCopy.svg";
import { ReactComponent as IconCommandDivider } from "../svgs/IconCommandDivider.svg";
import { ReactComponent as IconCommandExplode } from "../svgs/IconCommandExplode.svg";
import { ReactComponent as IconCommandGenerate1D } from "../svgs/IconCommandGenerate1D.svg";
import { ReactComponent as IconCommandGenerator } from "../svgs/IconCommandGenerator.svg";
import { ReactComponent as IconCommandGroup } from "../svgs/IconCommandGroup.svg";
import { ReactComponent as IconCommandLine } from "../svgs/IconCommandLine.svg";
import { ReactComponent as IconCommandMore } from "../svgs/IconCommandMore.svg";
import { ReactComponent as IconCommandMove } from "../svgs/IconCommandMove.svg";
import { ReactComponent as IconCommandPan } from "../svgs/IconCommandPan.svg";
import { ReactComponent as IconCommandPoint } from "../svgs/IconCommandPoint.svg";
import { ReactComponent as IconCommandPolyline } from "../svgs/IconCommandPolyline.svg";
import { ReactComponent as IconCommandScale } from "../svgs/IconCommandScale.svg";
import { ReactComponent as IconCommandSelect } from "../svgs/IconCommandSelect.svg";
import { ReactComponent as IconCommandText } from "../svgs/IconCommandText.svg";
import { ReactComponent as IconCommandUngroup } from "../svgs/IconCommandUngroup.svg";
import { ReactComponent as IconCommandZoom } from "../svgs/IconCommandZoom.svg";

const matcher = {
  "block": IconCommandBlock,
  "command": IconCommandCommand,
  "copy": IconCommandCopy,
  "divider": IconCommandDivider,
  "explode": IconCommandExplode,
  "generate1d": IconCommandGenerate1D,
  "generator": IconCommandGenerator,
  "group": IconCommandGroup,
  "line": IconCommandLine,
  "more": IconCommandMore,
  "move": IconCommandMove,
  "pan": IconCommandPan,
  "point": IconCommandPoint,
  "polyline": IconCommandPolyline,
  "scale": IconCommandScale,
  "select": IconCommandSelect,
  "text": IconCommandText,
  "ungroup": IconCommandUngroup,
  "zoom": IconCommandZoom,
};

export type IconCommandType = keyof typeof matcher;

interface IconCommandProps {
  type: IconCommandType;
}

const IconCommand: React.FC<IconCommandProps> = ({ type }) => {
  const IconComponent = matcher[type];
  return <IconComponent />;
};

export default IconCommand;
