import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";

// 컨텍스트 생성
const KeyContext = createContext();

// 텍스트로 변환 가능한 키만 처리하는 함수
const isTextKey = (key) => {
  // 특정 키 코드를 필터링 (Ctrl, Shift, Alt, Enter 등 제외)
  const nonTextKeys = [
    "ControlLeft",
    "ControlRight",
    "ShiftLeft",
    "ShiftRight",
    "AltLeft",
    "AltRight",
    "Enter",
    "Space",
    "Escape",
    "ArrowUp",
    "ArrowDown",
    "ArrowLeft",
    "ArrowRight",
  ];
  return !nonTextKeys.includes(key);
};

// 컨텍스트 프로바이더 컴포넌트
export function GlobalKeyDownProvider({ children }) {
  const [pressed_keys, setPressedKeys] = useState([]);
  const [current_action, setCurrentAction] = useState("pending");
  const pressed_text = useMemo(
    () =>
      pressed_keys
        .filter((e) => isTextKey(e.code))
        .map((e) => e.key)
        .join(""),
    [pressed_keys]
  );

  const resetKeys = () => {
    setPressedKeys([]);
    setCurrentAction("pending");
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      const activeElement = document.activeElement;

      // 입력 필드나 텍스트 에어리어에 포커스가 있는지 확인
      if (
        activeElement.tagName === "INPUT" ||
        activeElement.tagName === "TEXTAREA"
      ) {
        return;
      }
      // 포커스가 input 또는 textarea에 없는 경우에만 키 이벤트 처리
      //   console.log("Key pressed outside input: ", event.key, event.code);
      // Enter, Space, or Escape 키가 입력되면 문자열을 처리
      if (event.code === "Enter" || event.code === "Space") {
        // console.log("Buffer content:", pressed_keys.map((e) => e.key).join("")); // 입력된 문자열 처리
        setCurrentAction("next");
      } else if (event.code === "Escape") {
        setCurrentAction("exit");
      } else {
        setCurrentAction("key");
      }
      if (event.code === "Backspace") {
        setPressedKeys((keys) => keys.slice(0, keys.length - 1));
      } else {
        // 다른 키가 입력되면 문자열을 버퍼에 추가
        setPressedKeys((keys) => [
          ...keys,
          { key: event.key, code: event.code },
        ]);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [pressed_keys]);

  return (
    <KeyContext.Provider
      value={{
        pressed_keys,
        pressed_text,
        current_action,
        setPressedKeys,
        resetKeys,
      }}
    >
      {children}
    </KeyContext.Provider>
  );
}

const useGlobalKeyDown = () => {
  return useContext(KeyContext);
};

export default useGlobalKeyDown;
