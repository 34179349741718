import Konva from "konva";
import useDrawContext, {
  Abstract,
  DesignatorLayer,
  DesignObject,
  HatchType,
  Material,
} from "../hooks/useDrawContext";
import { Circle, Group, Line, Text } from "react-konva";
import { layerToLinestyle } from "../utils/KonvaUtils";
import { abstractify } from "../utils/ObjectUtils";
import { SelectedDesignObject } from "../components/Canvas";
import { fadedOpacity, selectedColor } from "../utils/CanvasConstants";
import { useMemo, useState } from "react";
import { BlockProps, getConnectedSegments } from "../utils/DetailUtils";
import Hatch from "./Hatch";
import { getXYCrossing } from "../utils/GeomUtils";

const BoundaryDetailBlock = ({ block }: { block: BlockProps }) => {
  const { draw_context, getDesignObject, getNamedObject } = useDrawContext();

  let design_object = getDesignObject(
    block.design_objects[block.active_object_index]
  );
  let material = getNamedObject(block.materials[block.active_object_index]);
  let boundary = block.points;

  // check layer activated
  let design_object_layer = getNamedObject(design_object.layer);

  // is selected
  const is_selected =
    SelectedDesignObject(draw_context)?.uuid === design_object.uuid;

  // is_scope
  const is_scope = block.design_objects.some(
    (x) => x.uuid === draw_context.scope?.uuid
  );

  // is faded
  const is_faded = !is_scope && draw_context.scope !== null;

  // style override
  let style_override = {};
  if (is_faded) {
    style_override["opacity"] = fadedOpacity;
  }
  if (is_selected) {
    style_override["stroke"] = selectedColor;
    style_override["fill"] = selectedColor;
  }

  // active sides
  let active_sides = block.active_sides;

  // outline
  const outline_layer = useMemo(
    () => getNamedObject(material.outlineLayer),
    [material, getNamedObject]
  );
  const linestyle = useMemo(
    () =>
      layerToLinestyle(
        outline_layer,
        getNamedObject,
        draw_context.global_setting
      ),
    [outline_layer, getNamedObject, draw_context]
  );

  let active_segments = getConnectedSegments(boundary, active_sides);

  const SegmentLine = ({ segment }: { segment: number[] }) => {
    return (
      <Line
        layer={abstractify(outline_layer)}
        points={segment}
        {...linestyle}
        {...style_override}
        closed={segment.length === 10}
        fill={null}
        strokeScaleEnabled={
          draw_context.global_setting.rendering_style === "print"
        }
      />
    );
  };

  // hatch
  let hatch: HatchType = getNamedObject(material.hatch);
  let hatch_layer: DesignatorLayer = getNamedObject(material.hatchLayer);

  function getPolygonMidpoint(points: number[]): [number, number] {
    let xSum = 0;
    let ySum = 0;
    const numPoints = points.length / 2; // Each point is defined by two values (x and y)

    for (let i = 0; i < points.length; i += 2) {
      xSum += points[i]; // x-coordinate
      ySum += points[i + 1]; // y-coordinate
    }

    const midpointX = xSum / numPoints;
    const midpointY = ySum / numPoints;

    return [midpointX, midpointY];
  }

  const hatch_props = useMemo(
    () => ({
      type: hatch?.value,
      boundary,
      pivot_point: hatch?.value.includes("Ins")
        ? getXYCrossing(
            [boundary[0], boundary[1]],
            [boundary[2] - boundary[0], boundary[3] - boundary[1]]
          )
        : [0, 0],
      unit_direction: hatch?.value.includes("Ins")
        ? [boundary[2] - boundary[0], boundary[3] - boundary[1]]
        : [1, 0],
      scale: hatch?.value.includes("Ins") ? (material.thickness ?? 1) / 100 : 1,
      layer: abstractify(hatch_layer),
    }),
    [boundary, hatch, hatch_layer]
  );

  return (
    <Group>
      {design_object_layer.activated && outline_layer.activated
        ? active_segments.map((segment) => <SegmentLine segment={segment} />)
        : null}
      <Hatch {...hatch_props} />
      {/* <Text
        x={getPolygonMidpoint(block.points)[0]}
        y={getPolygonMidpoint(block.points)[1]}
        text={`${block.grid_i}, ${block.grid_j}, ${block.active_object_index}`}
      /> */}
      {/* <Circle
        x={boundary[2]}
        y={boundary[3]}
        radius={3}
        stroke={"#aaaaaa"}
        strokeWidth={1}
      /> */}
    </Group>
  );
};

export const BlockClickArea = ({
  block,
  onClick,
}: {
  block: BlockProps;
  onClick: any;
}) => {
  const { draw_context, getDesignObject, getNamedObject } = useDrawContext();

  let design_object = getDesignObject(
    block.design_objects[block.active_object_index]
  );
  let material = getNamedObject(block.materials[block.active_object_index]);
  let boundary = block.points;

  // check layer activated
  let design_object_layer = getNamedObject(design_object.layer);

  // is selected
  const is_selected =
    SelectedDesignObject(draw_context)?.uuid === design_object.uuid;

  // is_scope
  const is_scope = block.design_objects.some(
    (x) => x.uuid === draw_context.scope?.uuid
  );

  // is faded
  const is_faded = !is_scope && draw_context.scope !== null;

  // style override
  let style_override = {};
  if (is_faded) {
    style_override["opacity"] = fadedOpacity;
  }
  if (is_selected) {
    style_override["stroke"] = selectedColor;
    style_override["fill"] = selectedColor;
  }
  const [opacity, setOpacity] = useState(0);

  return design_object_layer.activated && is_scope ? (
    <Line
      block={block}
      layer={abstractify(design_object.layer)}
      points={boundary}
      closed={true}
      stroke={null}
      // fill={null}
      fill={selectedColor}
      opacity={opacity}
      onClick={onClick}
      onMouseEnter={() => setOpacity(0.1)}
      onMouseLeave={() => setOpacity(0)}
    />
  ) : null;
};

export default BoundaryDetailBlock;
